import { Component, OnInit } from '@angular/core';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
import { AccountsService } from '../../../services/api/accounts/accounts.service';
import { LoginService } from '../../../services/api/login/login.service';
import * as jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  language=localStorage.getItem('language');
  toggleNavbar = true;
  public token =localStorage.getItem('currentUser');
  error: string;
  userInfos = [] ;

    constructor(private translateService: TranslateService, private account: AccountsService,private toastr: ToastrService,private authenticationService: AuthService) {
      }
    
  ngOnInit(): void {
    this.getDataUsers();

  }
  changelanguage(language, event){
    event.preventDefault();
    this.language=language;
    this.translateService.use(language);
    localStorage.setItem('language', language);

  }
  getDataUsers(){
    let userData = jwt_decode(this.token);  
    this.account.getUserById(userData.userId).subscribe((user: any[])=>{
      this.userInfos = user;
    },error => {
      this.error = error;
      this.toastr.error(error);
  })

  }
  logout(log){
    this.authenticationService.logout();

  }

}
