<div class="container pt-4" style="margin-top: 20px; padding-left: 0; padding-right: 0;">
    <div class="pagecontent">
            <div class="bloc_left ">
                <section class="tile tile-fix-left" style="position: fixed">
                    <div class="dvd dvd-btm clearfix">
                        <div class="tile-header float-left">
                            <h1 class="custom-font">
                                <strong>{{ 'Reports.CMP.DASHBOARD.LIST.FILTRES.TITLE' | translate }}</strong>
                            </h1>
                        </div>

                    </div>
                    <div class="tile-body filter-left">
                        <div class="pb-10 ">
                            <label style="width: 100%">{{ 'Reports.CMP.DASHBOARD.LIST.FILTRES.TYPE' | translate }}: </label>
                            <mat-button-toggle-group [(value)] = "getParams.supportType" #group="matButtonToggleGroup" class="support-type">
                                <mat-button-toggle value="1" (change)="notFirst();doNotRecalculateAnnualStat();ajaxSendings();">{{ 'Supporttype.1' | translate }}</mat-button-toggle>
                                <mat-button-toggle value="2" (change)="notFirst();doNotRecalculateAnnualStat();ajaxSendings();">{{ 'Supporttype.2' | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                            <label style="width: 100%; padding-top: 10px;" >{{ 'Reports.CMP.DASHBOARD.LIST.FILTRES.DATE' | translate}}:</label>
                            <div class="colorplaceholder" >
                                <mat-form-field appearance="outline" >
                                <mat-date-range-input [rangePicker]="picker"  [formGroup]="range"  >

                                    <input matStartDate placeholder="{{dateStart}}" formControlName="start" (dateChange)="notFirst();doNotRecalculateAnnualStat();formatDate($event)"  >
                                    <input matEndDate placeholder="{{dateEnd}}" formControlName="end">

                                </mat-date-range-input>

                                <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>

                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field></div>
                            <label for="list" class="pt-15" style="margin-top: 0px; width: 100%;">{{ 'Reports.CMP.SENDING.LIST.TRIGGER.LABEL' | translate }} :</label>
                            <mat-button-toggle-group [(value)] = "getParams.type" #group="matButtonToggleGroup" name="fontStyle" aria-label="Font Style" >
                                <mat-button-toggle value="classic" (change)="notFirst();doNotRecalculateAnnualStat();ajaxSendings()">{{ 'Reports.CMP.SENDING.LIST.TRIGGER.CLASSIC' | translate }}</mat-button-toggle>
                                <mat-button-toggle value="trigger" (change)="notFirst();doNotRecalculateAnnualStat();ajaxSendings()">{{ 'Reports.CMP.SENDING.LIST.TRIGGER.TRIGGER' | translate }}</mat-button-toggle>
                                <mat-button-toggle value="apicall" (change)="notFirst();doNotRecalculateAnnualStat();ajaxSendings()">{{ 'Reports.CMP.SENDING.LIST.TRIGGER.APICALL' | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </section>
            </div>
            <div class="bloc_right" style="padding: 0;">
                <section class="tile tile-cmp" >
                    <div class="dvd dvd-btm clearfix">
                        <div class="tile-header pull-left">
                            <h1 class="custom-font">
                                <strong>{{ 'Reports.CMP.DASHBOARD.GRAPH.HEADER.H1STR' | translate }}</strong>
                            </h1>
                        </div>
                    </div>
                    <div class="tile-body">
                        <div class="loadingbloc" *ngIf="chartData.length == 0">
                            <div class="loading-node-svg-container" ng-style="{width: size*20}">
                                <svg class="loading-node-svg loading-node-svg-l" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                                    <path class="loading-node-l" d="M104.622,252.056 C111.991,248.159 141.299,229.032 135.218,205.924 C130.632,188.491 71.968,170.057 79.587,119.086 C85.937,76.603 121.263,39.657 121.311,24.107 C87.189,67.257 24.406,102.534 23.955,140.795 C23.234,201.996 64.938,215.733 104.622,252.056 Z"/>
                                </svg><!----><svg class="loading-node-svg loading-node-svg-r" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                                <path class="loading-node-r" d="M95.424,40.115 C87.938,44.048 58.202,63.402 64.603,87.127 C69.433,105.023 129.439,124.390 122.103,176.547 C115.989,220.019 80.267,257.579 80.352,273.510 C114.793,229.583 178.539,193.957 178.671,154.762 C178.883,92.068 136.219,77.653 95.424,40.115 Z"/>
                            </svg>
                            </div>
                        </div>
                        <div *ngIf="chartData.length != 0">
                            <div class="chart-wrapper">
                                <canvas baseChart *ngIf="lineChartData.length != 0"
                                        [datasets]="lineChartData"
                                        [labels]="lineChartLabels"
                                        [options]="lineChartOptions"
                                        [colors]="lineChartColors"
                                        [legend]="lineChartLegend"
                                        [chartType]="lineChartType"
                                        [plugins]="lineChartPlugins">
                                </canvas>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="stat-sending__data-block bg-green bg">
                                        <span class="stat-sending__data-block__back-icon hub-recieved">
                                        </span>
                                        <div class="stat-sending__data-block__content">
                                            <div class="stat-sending__data--fixed">
                                                {{ (StatMails.NbCampagne | number:'':format) || 0 }}
                                            </div>
                                            <div class="stat-sending__data-block__title">
                                                {{ 'Reports.CMP.DASHBOARD.GRAPH.CAMPAGNECOMPLETED' | translate }}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="stat-sending__data-block bg-drank bg">
                                    <span class="stat-sending__data-block__back-icon hub-sended"></span>
                                    <div class="stat-sending__data-block__content">
                                        <div class="stat-sending__data--fixed">
                                            {{ (StatMails.nbSend | number:'':format) || 0 }}
                                        </div>
                                        <div class="stat-sending__data-block__title" *ngIf="getParams.supportType == '1'">
                                            {{ 'Reports.CMP.DASHBOARD.GRAPH.SENDEMAIL' | translate }}

                                        </div>
                                        <div class="stat-sending__data-block__title ng-binding" *ngIf="getParams.supportType == '2'">
                                            {{ 'Reports.CMP.DASHBOARD.GRAPH.SENDSMS' | translate }}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="stat-sending__data-block bg-green bg">
                                    <span class="stat-sending__data-block__back-icon hub-recieved"></span>
                                    <div class="stat-sending__data-block__content">
                                        <div class="stat-sending__data--fixed ng-binding">
                                            {{ (StatMails.nbDelivred | number:'':format) || '0' }}
                                        </div>
                                        <div class="stat-sending__data-block__title ng-binding" *ngIf="getParams.supportType == '1'">
                                            {{ 'Reports.CMP.DASHBOARD.GRAPH.RECIEVEDEMAIL' | translate}}

                                        </div>
                                        <div class="stat-sending__data-block__title ng-binding" *ngIf="getParams.supportType == '2'">
                                            {{ 'Reports.CMP.DASHBOARD.GRAPH.RECIEVEDSMS' | translate}}

                                        </div>
                                        <div class="stat-sending__data--percent ng-binding">
                                            {{ (StatMails.nbDelivred / StatMails.nbSend * 100 | number: '1.2-2':format) || '0' }}%
                                            <span class="stat-sending__data--percentof">{{ 'Reports.CMP.SENDING.STATS.OFSENDEDVOLUME' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="stat-sending__data-block stat-sending__data-block--small bg bg-orange" *ngIf="getParams.supportType == '1'">
                                    <span class="stat-sending__data-block__back-icon hub-opener"></span>
                                    <div class="stat-sending__data-block__content">
                                        <div class="stat-sending__data--fixed ng-binding">
                                            {{ (StatMails.nbUniqOpen | number:'':format) || '0' }}
                                        </div>
                                        <div class="stat-sending__data-block__title ng-binding">
                                            {{ 'Reports.CMP.DASHBOARD.GRAPH.OPENERS' | translate }}

                                        </div>
                                        <div class="stat-sending__data--percent ng-binding">
                                            {{ (StatMails.nbUniqOpen / StatMails.nbDelivred * 100 | number: '1.2-2':format) || '0' }}%
                                            <span class="stat-sending__data--percentof">{{ 'Reports.CMP.SENDING.STATS.OFRECIEVERS' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="stat-sending__data-block stat-sending__data-block--small bg bg-orange lter" *ngIf="getParams.supportType == '1'">
                                    <span class="stat-sending__data-block__back-icon hub-open"></span>
                                    <div class="stat-sending__data-block__content">
                                        <div class="stat-sending__data--fixed ng-binding">
                                            {{ (StatMails.nbOpen | number:'':format) || '0' }}
                                        </div>
                                        <div class="stat-sending__data-block__title ng-binding">
                                            {{ 'Reports.CMP.DASHBOARD.GRAPH.OPENS' | translate }}

                                        </div>
                                        <div class="stat-sending__data--percent ng-binding">
                                            {{ (StatMails.nbOpen / StatMails.nbUniqOpen | number:'':format) || '0' }}
                                            <span class="stat-sending__data--percentof ng-binding">
                                                {{ 'Reports.CMP.SENDING.STATS.AVERAGEOPEN' | translate }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="stat-sending__data-block stat-sending__data-block--small bg bg-blue">
                                    <span class="stat-sending__data-block__back-icon hub-clicker"></span>
                                    <div class="stat-sending__data-block__content">
                                        <div class="stat-sending__data--fixed ng-binding">
                                            {{ (StatMails.nbUniqClic| number:'':format) || '0' }}
                                        </div>
                                        <div class="stat-sending__data-block__title ng-binding">
                                            {{ 'Reports.CMP.DASHBOARD.GRAPH.CLICKERS' | translate }}
                                        </div>
                                        <div class="stat-sending__data--percent ng-binding">
                                            {{ (StatMails.nbUniqClic / StatMails.nbDelivred * 100 | number: '1.2-2':format) || '0' }}%
                                            <span class="stat-sending__data--percentof">{{ 'Reports.CMP.SENDING.STATS.OFRECIEVERS' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="stat-sending__data-block stat-sending__data-block--small bg bg-blue lter">
                                    <span class="stat-sending__data-block__back-icon hub-click"></span>
                                    <div class="stat-sending__data-block__content">
                                        <div class="stat-sending__data--fixed ng-binding">
                                            {{ (StatMails.nbClic | number:'':format) || '0' }}
                                        </div>
                                        <div class="stat-sending__data-block__title ng-binding">
                                            {{ 'Reports.CMP.DASHBOARD.GRAPH.CLICKS' | translate }}
                                        </div>
                                        <div class="stat-sending__data--percent ng-binding">
                                            {{ (StatMails.nbClic / StatMails.nbUniqClic | number:'':format) || '0' }}
                                            <span class="stat-sending__data--percentof">{{ 'Reports.CMP.SENDING.STATS.AVERAGECLICK' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section class="tile cmp">
                    <div class="dvd dvd-btm clearfix col-12">
                        <div class="tile-header pull-left">
                            <h1 class="custom-font">
                                <strong>{{ 'Reports.CMP.DASHBOARD.LIST.HEADER.H1STR' | translate }}</strong> {{ 'Reports.CMP.DASHBOARD.LIST.HEADER.H1NOR' | translate }}
                            </h1>
                        </div>

                        <div class="float-right">
                            <div class="btn btn-intileheader btn-drank form-control"  (click)="results()" *ngIf="MailSending.length != 0">
                                <i class="fa fa-upload ml-5"></i> {{ 'Reports.CMP.DASHBOARD.LIST.EXPORT' | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="tile-body">
                        <div class="loadingblocMailSending" *ngIf="MailSending.length == 0">
                            <div class="loading-node-svg-container" ng-style="{width: size*20}">
                                <svg class="loading-node-svg loading-node-svg-l" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                                    <path class="loading-node-l" d="M104.622,252.056 C111.991,248.159 141.299,229.032 135.218,205.924 C130.632,188.491 71.968,170.057 79.587,119.086 C85.937,76.603 121.263,39.657 121.311,24.107 C87.189,67.257 24.406,102.534 23.955,140.795 C23.234,201.996 64.938,215.733 104.622,252.056 Z"/>
                                </svg><!----><svg class="loading-node-svg loading-node-svg-r" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                                <path class="loading-node-r" d="M95.424,40.115 C87.938,44.048 58.202,63.402 64.603,87.127 C69.433,105.023 129.439,124.390 122.103,176.547 C115.989,220.019 80.267,257.579 80.352,273.510 C114.793,229.583 178.539,193.957 178.671,154.762 C178.883,92.068 136.219,77.653 95.424,40.115 Z"/>
                            </svg>
                            </div>
                        </div>
                        <div *ngIf="MailSending.length != 0">
                            <div class=" col-12">
                                <div class="clearfix filters__core">
                                    <div class="float-left filters__core__left">
                                        <div class="form-inline">
                                            <div class="input-group">
                                                <input [(ngModel)]="name" placeholder="{{ 'Reports.CMP.SENDING.LIST.SEARCH.PLACEHOLDER' | translate }}" class="form-control" type="search"/>
                                                <div class="input-group-btn"><div class="btn btn-drank btn-square"><i class="fa fa-search"></i></div></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pull-right filters__core__right">
                                        <div class="form-inline">


                                            <div class="form-group">
                                                <label for="">{{ 'Reports.CMP.SENDING.LIST.DISPLAYED.LABEL' | translate }} :</label>
                                                <select class="form-control" #selectElem (change)="handlePageSizeChange($event)">
                                                    <option [value]="i" *ngFor="let i of displayedItemsOptions">{{ i }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-10"></div>
                            <div class="table-responsive table-widthmax ">
                                <table class="table table-hover  table-bordered">
                                    <thead>
                                    <tr>
                                        <th class="report-sending__column__type"></th>
                                        <th class="report-sending__column__volume"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.ACCOUNT' | translate }}</strong></th>
                                        <th class="report-sending__column__date"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.VOLUME' | translate }}</strong></th>
                                        <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.VOLUMERECIEVED' | translate }}</strong></th>
                                        <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.UNIQOPEN' | translate }}</strong></th>
                                        <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.UNIQCLIC' | translate }}</strong></th>
                                        <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.REACTIVITY' | translate }}</strong></th>
                                        <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.UNSUBSCRIBE' | translate }}</strong></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let sending of MailSending.slice(0,getParams.limit) | filter:name">
                                        <th scope="row">
                                            <div class="p-5 text-center iconeenvelope" [ngClass]="">
                                                <i class="fa fa-envelope-o" ></i>
                                            </div>
                                        </th>
                                        <td class="variable-width-cell" uib-tooltip-template="'campagneThemeTooltip'" tooltip-enable="sending.campagne || sending.theme" tooltip-placement="bottom">
                                            {{sending .compte}}
                                        </td>
                
                                        <td class="report-sending__column__volume">{{ (sending.volumeSend | number:'':format) || '0'}}</td>
                                        <td class="report-sending__column__data">
                                            <div class="progress" style="position: relative;">
                                                <div class="progress-bar " role="progressbar" style="width: {{ (sending.rateRecieved ) || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="stat_nbre">
                                                        <span class="text-span-progressbar">{{ (sending.volumeRecieved | number:'':format) || '0'}}</span>
                                                        <span class="text-sm"> |{{ (sending.rateRecieved | number: '1.1-2':format) || '0'}}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="report-sending__column__data">
                                            <div class="progress" style="position: relative;">
                                                <div class="progress-bar " role="progressbar" style="width:{{ (sending.uniqOpenBySendRate)  || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="stat_nbre">
                                                        <span class="text-span-progressbar">{{ (sending.uniqOpenBySend | number:'':format) || '0'}}</span>
                                                        <span class="text-sm"> |{{ (sending.uniqOpenBySendRate | number: '1.1-2':format) || '0'}}%</span>
                                                    </div>
                                                </div>
                                            </div>
                
                                        </td>
                                        <td class="report-sending__column__data">
                                            <div class="progress" style="position: relative;">
                                                <div class="progress-bar " role="progressbar" style="width: {{ (sending.uniqClicBySendRate) || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="stat_nbre">
                                                        <span class="text-span-progressbar">{{ (sending.uniqClicBySend | number:'':format) || '0'}}</span>
                                                        <span class="text-sm"> |{{ (sending.uniqClicBySendRate | number: '1.1-2':format) || '0'}}%</span>
                                                    </div>
                                                </div>
                                            </div>
                
                                        </td>
                                        <td class="report-sending__column__data">
                                            <div class="progress" style="position: relative;">
                                                <div class="progress-bar " role="progressbar" style="width: {{ (sending.reactivity ) || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="stat_nbre">
                                                        <span>{{ (sending.reactivity | number:'':format) || '0'}}%</span>
                                                    </div>
                                                </div>
                                            </div>
                
                                        </td>
                                        <td class="report-sending__column__data">
                                            <div class="progress" style="position: relative;">
                                                <div class="progress-bar " role="progressbar" style="width: {{ (sending.unsubscribeAfterRate ) || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                                    <div class="stat_nbre">
                                                        <span class="text-span-progressbar">{{ (sending.unsubscribeAfter | number:'':format) || '0'}}</span> 
                                                    <span class="text-sm"> |{{ (sending.unsubscribeAfterRate | number: '1.1-2':format) || '0'}}%</span>
                                                </div>
                                            </div>
                                            </div>
                                        </td>
                
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="tile cmp">
                    <div class="dvd dvd-btm clearfix">
                        <div class="tile-header pull-left">
                            <h1 class="custom-font" ><strong>{{'Reports.CMP.DASHBOARD.LIST.ANNUEL.TITLE1' | translate}}</strong>{{'Reports.CMP.DASHBOARD.LIST.ANNUEL.TITLE2' | translate}}
                            </h1>
                        </div>
                    </div>
                    <div class="tile-body">
                        <div class="loadingblocAnnuel" *ngIf="volumeAnnuels.length == 0">
                            <div class="loading-node-svg-container" ng-style="{width: size*20}">
                                <svg class="loading-node-svg loading-node-svg-l" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                                    <path class="loading-node-l" d="M104.622,252.056 C111.991,248.159 141.299,229.032 135.218,205.924 C130.632,188.491 71.968,170.057 79.587,119.086 C85.937,76.603 121.263,39.657 121.311,24.107 C87.189,67.257 24.406,102.534 23.955,140.795 C23.234,201.996 64.938,215.733 104.622,252.056 Z"/>
                                </svg><!----><svg class="loading-node-svg loading-node-svg-r" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                                <path class="loading-node-r" d="M95.424,40.115 C87.938,44.048 58.202,63.402 64.603,87.127 C69.433,105.023 129.439,124.390 122.103,176.547 C115.989,220.019 80.267,257.579 80.352,273.510 C114.793,229.583 178.539,193.957 178.671,154.762 C178.883,92.068 136.219,77.653 95.424,40.115 Z"/>
                            </svg>
                            </div>
                        </div>
                        <div *ngIf="volumeAnnuels.length != 0">
                            <div class="table-responsive table-widthmax ">
                                <table class="table table-hover  table-bordered">
                                    <thead>
                                    <tr>
                                        <th class="report-sending__column__data ng-binding"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.YEAR' | translate }}</strong></th>
                                        <th class="report-sending__column__data ng-binding"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.VOLUMEDATABASE' | translate }}</strong></th>
                                        <th class="report-sending__column__data ng-binding"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.VOLUMESEND' | translate }}</strong></th>
                                        <th class="report-sending__column__data ng-binding"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.VOLUMRECIEVED' | translate }}</strong></th>
                                        <th class="report-sending__column__data ng-binding"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.RECIEVED' | translate }}</strong></th>
                                        <th class="report-sending__column__data ng-binding"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.UNIQOPEN' | translate }}</strong></th>
                                        <th class="report-sending__column__data ng-binding"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.UNIQCLIC' | translate }}</strong></th>
                                        <th class="report-sending__column__data ng-binding"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.REACTIVITY' | translate }}</strong></th>
                                        <th class="report-sending__column__data ng-binding"><strong>SMS</strong></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let volume of volumeAnnuels">
                                        <td>{{volume.year}}</td>
                                        <td>{{volume.volume| number:'':format}}</td>
                                        <td>{{volume.volumeSend | number:'':format}}</td>
                                        <td>{{volume.volumeRecieved | number:'':format}}</td>
                                        <td>
                                            {{volume.recievedRate |number: '1.2-2':format }} %
                                        </td>
                                        <td>
                                            {{volume.openerRate | number: '1.2-2':format }} %
                                        </td>
                                        <td class="report-sending__column__data">
                                            {{volume.clickerRate | number: '1.2-2':format}} %
                                        </td>
                                        <td class="report-sending__column__data">
                                            {{volume.reactivityRate | number: '1.2-2':format}} %
                                        </td>
                                        <td class="report-sending__column__data">
                                            {{volume.volumesms | number: '':format}}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
</div>
