import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../services/api/accounts/accounts.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../../services/api/login/login.service';
import {TranslateService} from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as jwt_decode from 'jwt-decode';
import { from } from 'rxjs';
import { Location } from '@angular/common';
@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.scss']
})
export class AdduserComponent implements OnInit {
  public token =localStorage.getItem('currentUser');
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  error: string;
  annoncers = [] ;
  comptes = [];
  roles = [] ;
  role = [] ;
  map = [] ;
  addRole = [];
  addRoles = [];
  agencyid :string ;

  constructor(private account: AccountsService,private activatedRoute: ActivatedRoute,private login:LoginService,private formBuilder: FormBuilder,
    private router: Router,private toastr: ToastrService,private translate: TranslateService,private location: Location) { }

  ngOnInit(): void {
    this.getAllRoles();
    this.getDataAnnounce();
    this.getAgencyId();
    this.getUserRoles();
    this.registerForm = this.formBuilder.group({
      compte: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', Validators.required],
      plainPassword: ['', Validators.required],
      phone: [''],
      enabled:['']
  });
  }
  get f() { return this.registerForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return  this.toastr.error(this.translate.instant('FORMINVALID.TLE'));
        }

        this.loading = true;
        Object.keys(this.addRoles).forEach(key =>{
          if(this.addRoles[key]==1)
            this.map.push(key);
        });
        let userRoles = {'roles':this.map.toString()};

        this.registerForm.value.agency=this.agencyid.toString();
        this.account.post(this.registerForm.value)
            .subscribe(
                data => {
                    this.toastr.success(this.translate.instant('FORMINVALID.CREATE'), 'Done');
                    let  overwrite = 1;
                    this.account.postRoles(data['id'],userRoles,overwrite).subscribe(
                      data => {
                        this.toastr.success(this.translate.instant('FORMINVALID.ROLE'));
                        this.router.navigate(['/accounts']);
                      },  error => {
                        this.toastr.error(this.translate.instant('FORMINVALID.ERRORROLE'), 'Error');
                    })
                },
                error => {
                    this.toastr.error(error);
                    this.error = error;
                    this.loading = false;
                });
    }
    getAgencyId(){
      this.login.getUsetInfo().toPromise().then((data: any[])=>{
        this.agencyid = data['agency'].id;
       },
       error => {
        this.error = error;
        this.toastr.error(error);
    })
    }
  getAccounts(){
    this.account.getAllCompte().subscribe((data: any[])=>{
      this.comptes = data;
    })
  }
  getAllRoles(){
    let userData = jwt_decode(this.token);
    this.account.getUserRoles(userData.userId).subscribe((role: any[])=>{
      this.roles = role;
    },
    error => {
      this.error = error;
      this.toastr.error(error);
  })
  }
  getUserRoles(){
    let userData = jwt_decode(this.token);
    this.account.getUserRoles(userData.userId).subscribe((role: any[])=>{
      this.addRoles = role ;
      Object.keys(this.addRoles).forEach(key =>{
        if(this.addRoles[key]==1)
        this.addRoles[key] = "0";
      });
    },
    error => {
      this.error = error;
      this.toastr.error(error);
  })
  }
  getDataAnnounce(){
    this.login.getUsetInfo().toPromise().then((data: any[])=>{
      this.account.getAnnounces(data).subscribe((data: any[])=>{
        this.annoncers = data;
      },
      error => {
        this.error = error;
        this.toastr.error(error);
    })

    });

  }
  checkboxAdd(key){
    if(key != null){
      this.role.push(key);
    }
  }
  checkChange(item){
  if (this.addRoles[item.key] == true || this.addRoles[item.key] == 1)
    this.addRoles[item.key] = "0";
  else
    this.addRoles[item.key] = "1";
  }
  goBack() {
    this.location.back();
  }


}
