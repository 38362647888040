import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../services/api/accounts/accounts.service';
import { LoginService } from '../../services/api/login/login.service';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import {Router, ActivatedRoute, Params} from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {Location} from "@angular/common";
import { ConfirmationDialogComponentComponent } from '../../confirmation-dialog/confirmation-dialog-component/confirmation-dialog-component.component';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss']
})
export class EdituserComponent implements OnInit {
  private map= [];

  public token =localStorage.getItem('currentUser');
  public userData = jwt_decode(this.token);
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  stat = false;
  userRoles :{};
  error: string;
  comptes = [];
  roles = [] ;
  role = [] ;
  userId = Number ;
  users = [] ;
  annonces = [] ;
  editRole = [];
  roleuser = [];

  user = {
    username: '',
    password: '',
    passwordConfirm:'',
    email:'',
    firstName:'',
    lastName:'',
    phone:'',
    compte:'',
    published: false
  };

  constructor(private account: AccountsService,private activatedRoute: ActivatedRoute,private login:LoginService,private formBuilder: FormBuilder,private router: Router,private toastr: ToastrService,private translate: TranslateService,private dialog: MatDialog,private location: Location) { }

  getAllRoles(){
    let iduser = this.activatedRoute.snapshot.paramMap.get('id');
    this.account.getUserRoles(iduser).subscribe((role: any[])=>{
      this.roles = role;
      this.editRole = role;
    },error => {
      this.error = error;
      this.toastr.error(error);
  })
  }
  getUserRoles(){
    let userData = jwt_decode(this.token);
    this.account.getUserRoles(userData.userId).subscribe((role: any[])=>{
      this.roleuser = role;
    },
    error => {
      this.error = error;
      this.toastr.error(error);
  })
  }
  getUser(){
    let iduser = this.activatedRoute.snapshot.paramMap.get('id');
    this.account.getUserById(iduser).subscribe((user: any[])=>{
      this.users = user;

    },
    error => {
      this.error = error;
      this.toastr.error(error);
  })
   }
   getDataAnnounce(){
    this.login.getUsetInfo().toPromise().then((data: any[])=>{
      this.account.getAnnounces(data).subscribe((data: any[])=>{
        this.annonces = data;
      },
      error => {
        this.error = error;
        this.toastr.error(error);
    })

    });
  }
  ngOnInit(): void {
    let iduser = this.activatedRoute.snapshot.paramMap.get('id');
    this.getUser();
    this.getAllRoles();
    this.getDataAnnounce();
    this.getUserRoles();
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', Validators.required],
      plainPassword: [''],
      phone: [''],
      enabled:['']
  });
  this.account.getUserById(iduser)
  .subscribe( data => {
    this.userId = data['compte'].id;
    this.registerForm.patchValue(data);

  });}
  get f() { return this.registerForm.controls; }


  saveUser(): void {
    let iduser = this.activatedRoute.snapshot.paramMap.get('id');
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }
    for (var i = 0; i < this.roles.length; i++) {

      this.role.push(this.roles[i]);
    }
    Object.keys(this.editRole).forEach(key =>{
      if(this.roles[key]==1)
        this.map.push(key);
    });
    this.userRoles = {'roles':this.map.toString()};
    this.loading = true;


    this.account.update(iduser,this.registerForm.value)
    .subscribe(
      data => {
        this.toastr.success(this.translate.instant('FORMINVALID.EDIT'), 'Done');
         let  overwrite = 1;
          this.account.postRoles(data['id'],this.userRoles,overwrite).subscribe(
            data => {
              this.toastr.success(this.translate.instant('FORMINVALID.ROLE'));
              this.router.navigate(['/accounts']);
            },  error => {
              this.toastr.error(this.translate.instant('FORMINVALID.ERRORROLE'), 'Error');
          })
      },
      error => {
          this.error = error;
          this.toastr.error(error);
          this.loading = false;
      });
  }
  checkboxAdd(item){
    this.role.push(item.key);


  }


checkChange(i, item){
  if (this.editRole[item.key] == true || this.editRole[item.key] == 1)
    this.editRole[item.key] = "0";
  else
    this.editRole[item.key] = "1";
}
  onSelect(userId) {
    this.toastr.warning(this.translate.instant('ACCOUNTS.ALERT'));
    window.open(environment.switch+'/#/login?token=' + localStorage.getItem('currentUser') + '&switchuser=' + userId);
  }

  checkbox(item){
     if(this.roles[item] == "1"){
        return true
      } else
      return false
  }
  goBack() {
    this.location.back();
  }

}
