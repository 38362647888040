import { Component, OnInit, Pipe } from '@angular/core';
import {Location} from '@angular/common';
import {AccountsService} from '../../services/api/accounts/accounts.service';
import * as jwt_decode from 'jwt-decode';
import { LoginService } from '../../services/api/login/login.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  displayedItemsOptions = [5, 10, 25, 50];
  public searchContent : string;
  config: any;
  page:number = 1;
  
  totalRecords:number;
  collection = { count: 60, data: [] };
  error: string; 
  getParams = {
    order: 'DESC',
    searchField: 'email',
    searchValue:'',
    orderBy: 'dateSend',
    offset: '0' ,
    limit: '5',
};
  datas = [];
  users = [] ;
  usersdata = [];
  constructor(
    private account: AccountsService,private login:LoginService,private toastr: ToastrService,private dialog: MatDialog,private translate: TranslateService
  )
  { 
  } 
  getUser(){
    this.login.getUsetInfo().subscribe((user: any[])=>{
      this.users['id'] = user;
      return this.users['id'];

    },error => {
      this.error = error;
      this.toastr.error(error);
  })
   }
   ajaxSendings() {
    let params = new HttpParams();
    if(this.getParams.searchValue){
      params=params.set('searchField', this.getParams.searchField);
      params=params.set('searchValue', this.getParams.searchValue);
      params=params.set('offset',  this.getParams.offset);
      params=params.set('limit', this.getParams.limit);
    } else {
      params=params.set('limit', this.getParams.limit);
      params=params.set('offset',  ((this.page - 1) * Number(this.getParams.limit)).toString());
    }
       
    this.login.getUsetInfo().toPromise().then((data: any[])=>{
     this.account.params = params;
    this.account.getLastConnectedUser(data).subscribe((data: any[])=>{
      this.usersdata = data['records'];
      this.totalRecords = data['count'];
    },error => {
      this.error = error;
      this.toastr.error(error);
  })
  })
   }
  getData(){
    this.login.getUsetInfo().toPromise().then((data: any[])=>{
      this.account.getDataAccounts(data).subscribe((data: any[])=>{
        this.datas = data;
      },error => {
        this.error = error;
        this.toastr.error(error);
    })

    });

  }
  ngOnInit(): void {
    this.getUser();
    this.getData();
    this.ajaxSendings()
  }
  color(p) {
    if(p==0) {return 'red'}
    if(p==1) {return '#5cb85c'}
}
  onSelect(userId) {
    this.toastr.warning(this.translate.instant('ACCOUNTS.ALERT'));

    window.open(environment.switch+'/#/login?token=' + localStorage.getItem('currentUser') + '&switchuser=' + userId);
  }
 
  handlePageChange(event): void {
    this.page = event;
    this.ajaxSendings();
  }

  handlePageSizeChange(event): void {
    this.getParams.limit = event.target.value;
    this.page = 1;
    this.ajaxSendings();
  }
  pageChanged(event): void {
    this.page = 1;
    this.ajaxSendings();
  }





}
