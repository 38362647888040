<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous">
<div class="col-12 bg-white">
    <app-header></app-header>

</div>
<div class="row">
    <div class="block_nav " >
        <app-nav></app-nav>

    </div>
    <div class="block_content">
        <div class="mt-4 ml-5">
            <app-page-controls></app-page-controls>
            <router-outlet></router-outlet>
        </div>

    </div>
</div>