<div class="page page-core page-login ">

    <div class="text-center">
        <img src="../../../assets/images/logo.png" class="login__logo" alt="HubScore">
    </div>

    <div class="mx-auto col-4 block_connexion ">

        <h2 class="title_page text-center">{{ 'Login.TITLE' | translate }}</h2>

        <form [formGroup]="loginForm" class="mt-20" novalidate (ngSubmit)="login()" >

            <div class="form-group" >
                <label for="loginUsername">{{ 'Login.USERMAME.LABEL' | translate }} :</label>
                <input id="loginUsername" name="username" formControlName="Username" type="text" class="form-control rounded" placeholder="{{ 'Login.USERMAME.PLACEHOLDER' | translate }}"  required>
                <p class="help-block">
                    <span *ngIf="submitted && f.Username.errors">{{ 'Form.HELP.FILLTHISINPUT' | translate }}</span>
                </p>
            </div>

            <div class="form-group" >
                <label for="loginPassword">{{ 'Login.PASSWORD.LABEL' | translate }} :</label>
                <input id="loginPassword" name="password" formControlName="Password" type="password" class="form-control rounded"  placeholder="{{ 'Login.PASSWORD.PLACEHOLDER' | translate }}"   required>
                <p class="help-block">
                    <span *ngIf="submitted && f.Password.errors">{{ 'Form.HELP.FILLTHISINPUT' | translate }}</span>
                </p>
            </div>
            <div *ngIf="error" class="alert alert-danger ">{{error}}</div>

            <div class="form-group clearfix float-right">
                    <button id="loginSubmit" type="submit" class="btn btn-drank b-0 br-2 mt-2 " >{{ 'Login.BUTTON' | translate }}</button>
            </div>

        </form>

    </div>

</div>
