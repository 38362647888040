import { Injectable,Pipe} from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map} from 'rxjs/operators';
import { tokenName } from '@angular/compiler';



@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }


}
