<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
<div class="page page-homepage" ng-intro="homepageIntroOptions" ng-intro-method="homepageIntroRun" style="margin-top: 20px;">
   <div class="row">
      <div class="col-lg-4 mb-20">
         <div class="row">
            <div class="col-lg-12 col-md-4" *ngFor="let item of annoncers| keyvalue">
               <div *ngIf="item.key == 0" id="myaccountInfosBtn"   class="text-center my-account__button my-account__button--drank" uib-btn-radio="'infos'">
                  {{ item.value.name }}
               </div>
               <div *ngIf="item.key == 1" id="myaccountInfosBtn"   class="text-center my-account__button my-account__button--green" uib-btn-radio="'infos'">
                  {{ item.value.name }}
               </div>
               <div *ngIf="item.key == 2" id="myaccountInfosBtn"   class="text-center my-account__button my-account__button--orange" uib-btn-radio="'infos'">
                  {{ item.value.name }}
               </div>
               <div *ngIf="item.key >= 3" id="myaccountInfosBtn"   class="text-center my-account__button my-account__button--drank" uib-btn-radio="'infos'">
                  {{ item.value.name }}
               </div>
            </div>
         </div>
      </div>
         
      <div class="col-md-8">
         <div class="container bg-white pt-4">
            <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" novalidate role="form" class="form-list">
            <section class="tile">
               <div class="tile-header">
                  <strong style="color: #5e0468;font-size: 30px">{{ 'ACCOUNTS.ADDTITLE' | translate }}</strong>
                  <br>
                  <p class="custom-font">
                     <strong>{{ 'ACCOUNTS.H1STR1' | translate }}</strong>{{ 'ACCOUNTS.H1NOR1' | translate }}
                  </p>
                  <div class="form-group formgroup_edit">
                     <label for="title" class="text-lg">{{ 'ACCOUNTS.NAME' | translate }}:</label>
                     <select class="form-control" formControlName="compte" name="compte">
                     <option [value]="i.id" *ngFor="let i of annoncers">{{ i.name }}</option>
                     </select>
                  </div>
               </div>
               <div class="checkbox mb-15">
                     <label class="checkbox checkbox-custom">
                       <input type="checkbox" name="enabled" id="agree" formControlName="enabled" ><i></i>
                       <label for="enabled" class="text-lg"> {{ 'ACCOUNTS.ENABLE' | translate }}</label>
                      
                     </label>
                   </div>
               <div class="form-group">
                     
                  <div class="form-group formgroup_edit">
                     <label for="username" class="text-lg">{{ 'ACCOUNTS.USERNAME' | translate }} :</label>
                     <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"/>
                     <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="form-group col-md-6">
                        <label for="password">{{ 'ACCOUNTS.PASSWORD' | translate }}:</label>
                        <input type="password"  class="form-control" />
                     </div>
                     <div class="form-group col-md-6">
                        <label for="password">{{ 'ACCOUNTS.RPNPASSWORD' | translate }}:</label>
                        <input type="password" formControlName="plainPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.plainPassword.errors }" />
                        <div *ngIf="submitted && f.plainPassword.errors" class="invalid-feedback">
                           <div *ngIf="f.plainPassword.errors.required">Password is required</div>
                           <div *ngIf="f.plainPassword.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                     </div>
                  </div>
                  <div class="form-group formgroup_edit">
                     <label for="email">{{ 'ACCOUNTS.EMAIL' | translate }}</label>
                     <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"/>
                     <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                     </div>
                  </div>
                  <div class="row">
                     <div class="form-group col-md-6">
                        <label for="firstName">{{ 'ACCOUNTS.FIRSTNAME' | translate }}: </label>
                        <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                        <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                           <div *ngIf="f.firstName.errors.required">First Name is required</div>
                        </div>
                     </div>
                     <div class="form-group col-md-6">
                        <label for="lastName">{{ 'ACCOUNTS.LASTNAME' | translate }}: </label>
                        <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                           <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                        </div>
                     </div>
                  </div>
                  <div class="form-group formgroup_edit">
                     <label for="phone">{{ 'ACCOUNTS.TEL' | translate }}</label>
                     <input type="text" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                  </div>
               </div>
               <div class="tile-header dvd dvd-btm">
                  <p class="custom-font"><strong>User </strong>roles edition</p>
               </div>
               <div class="tile-body">
                  <p class="text-muted">{{ 'Roles.LABEL' | translate }}</p>
                  <table class="table table-hover  table-bordered">
                     <thead>
                        <tr>
                           <th scope="col">{{ 'Roles.NAME' | translate }}</th>
                           <th >{{ 'Roles.ACTIVE' | translate }}</th>
                           <th>{{ 'Roles.Explication' | translate }}</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="form-group" *ngFor="let item of roles | keyvalue">
                           <td *ngIf="item.value == '1' && item.key != 'ROLE_USER'"><label class="control-label">{{ 'Role.' + item.key+'.label' | translate }}</label></td>
                           <td *ngIf="item.value == '1' && item.key != 'ROLE_USER'">
                              <div class="">
                                 <!-- Default switch -->
                                 <div class="custom-control custom-switch">
                                    <input type="checkbox" class="custom-control-input" id="{{item.key}}" id="{{item.key}}" (change)="checkChange(item)" name="userRoles[]" >
                                    <label class="custom-control-label" for="{{item.key}}">
                                    </label>
                                 </div>
                              </div>
                           </td>
                           <td *ngIf="item.value == '1' && item.key != 'ROLE_USER'">{{ 'Role.' + item.key+'.description' | translate }}</td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </section>
            <div class="pull-right">
               <button class="btn btn-drank">{{ 'ACCOUNTS.BTNADD' | translate }}</button>
            </div>
            </form>
            <div class="float-right">
                  <button (click)="goBack()" class="btn btn-default ml-2">{{ 'ACCOUNTS.Cancel' | translate }}</button>
            </div>
         </div>
      </div>
   </div>
</div>