import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import {Md5} from 'ts-md5/dist/md5';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    annuelSubject: Subject<any[]>;
    statMailSending: Subject<any[]>;
    mailStatSubject: Subject<any[]>;
    chartSubject: Subject<any[]>;
    private tokens: any[];
    private  service = this;
    public paramsMailSending: HttpParams;
    public paramsStat: HttpParams;
    public paramsChart: HttpParams;
    public calculateAnnualStat = true;
    public emitChartStat = true;
    constructor(public http: HttpClient,private toastr: ToastrService,private translateService: TranslateService) { }
    rateCalc(value, on) {
        var result;
        result = value / on * 100;
        if (isNaN(result)) {
            result = 0;
        }
        return result;
    };

    statMailSendingSubject() {
        this.statMailSending = new Subject<any[]>();

    }
    statMailSubject() {
        this.mailStatSubject = new Subject<any[]>();
    }
    chartDataSubject() {
        this.chartSubject = new Subject<any[]>();
    }
    annuelDataSubject() {
        this.annuelSubject = new Subject<any[]>();
    }
    getVolumeAnnuel(token) {
        let url = environment.url + environment.version + '/mailsends/volume/annuel.json' ;
        this.http.get(url, { headers: new HttpHeaders({'Authorization': 'Bearer ' + token})
        }).subscribe((data: any) => {
                for(let i = 0;i < data.length; i++){
                    let volumeAnnuel = {
                        "year" : data[i].year,
                        "volume": data[i].volume,
                        "volumeSend": data[i].volumesend,
                        "volumeRecieved": data[i].volumerecieved,
                        "uniqopenbysend": data[i].uniqopenbysend,
                        "uniqclicbysend": data[i].uniqclicbysend,
                        "volumesms": data[i].volumesms
                    };
                    this.emitAnnuelSubject(volumeAnnuel);
                }
            }
        );
    }
  getToken() {
    let service=this.service;
    let token = localStorage.getItem('currentUser');
      let id = localStorage.getItem('agencyId') + environment.key;
      let k = Md5.hashStr(id);
      let url = environment.url + environment.version + '/tokens/' + localStorage.getItem('agencyId') + '/users.json?k=' + k;

      this.http.get(url, {headers: new HttpHeaders({'Authorization': 'Bearer ' + token})
      }).subscribe((tokens: any[])=>{
          this.tokens = tokens;
          service.getStatCmp();
      });
  }

  emitAnnuelSubject(data) {
        this.annuelSubject.next(data);
    }
    emitStatMailSendingSubject(data) {
        this.statMailSending.next(data);
    }
    getStatMailSending(token) {
        let params = this.paramsMailSending;
        let url = environment.url + environment.version + '/mailsends.json';
        let userData = jwt_decode(token);
        let MailSending ={
                "compte":"",
                "volume":0,
                "volumeSend":0,
                "volumeRecieved":0,
                "uniqOpenBySend":0,
                "open":0,
                "uniqClicBySend":0,
                "clic":0,
                "clicByOpens":0,
                "reactivity":0,
                "hardBefore":0,
                "softBefore":0,
                "notOptinBefore":0,
                "badFormatedEmail":0,
                "hardAfter":0,
                "softAfter":0,
                "delivrabilityAfter":0,
                "unsubscribeAfter":0,
                "scompAfter":0,
                "rateRecieved":0,
                "uniqOpenBySendRate":0,
                "uniqClicBySendRate":0,
                "unsubscribeAfterRate":0,
                "logRecieved":0,
                "uniqOpenBySendLog":0,
                "uniqClicBySendLog":0,
                "reactivityLog":0,
                "unsubscribeAfterLog":0


        };
        this.getUserById(userData.userId).subscribe((data:any)=>{
            MailSending.compte = data.compte.name;
        });

        this.http.get(url, { params, headers: new HttpHeaders({'Authorization': 'Bearer ' + token})}).subscribe((data: any) => {
           for(let i = 0;i < data.records.length; i++){
               if(data.records[i].globalStats){

                   MailSending.volume=MailSending.volume + data.records[i].globalStats.volume;
                   if(data.records[i].globalStats.volumeSend){
                       MailSending.volumeSend=MailSending.volumeSend + data.records[i].globalStats.volumeSend;
                   }
                   if(data.records[i].globalStats.volumeRecieved){
                       MailSending.volumeRecieved = MailSending.volumeRecieved + data.records[i].globalStats.volumeRecieved;
                   }
                   if(data.records[i].globalStats.uniqOpenBySend){
                       MailSending.uniqOpenBySend=MailSending.uniqOpenBySend + data.records[i].globalStats.uniqOpenBySend;
                   }
                   if(data.records[i].globalStats.open){
                       MailSending.open=MailSending.open + data.records[i].globalStats.open;
                   }
                   if(data.records[i].globalStats.uniqClicBySend){
                       MailSending.uniqClicBySend=MailSending.uniqClicBySend + data.records[i].globalStats.uniqClicBySend;
                   }
                   if(data.records[i].globalStats.clic){
                       MailSending.clic=MailSending.clic + data.records[i].globalStats.clic;
                   }
                   if(data.records[i].globalStats.hardBefore){
                       MailSending.hardBefore=MailSending.hardBefore + data.records[i].globalStats.hardBefore;
                   }
                   if(data.records[i].globalStats.softBefore){
                       MailSending.softBefore=MailSending.softBefore + data.records[i].globalStats.softBefore;
                   }
                   if(data.records[i].globalStats.notOptinBefore){
                       MailSending.notOptinBefore=MailSending.notOptinBefore + data.records[i].globalStats.notOptinBefore;
                   }
                   if(data.records[i].globalStats.badFormatedEmail){
                       MailSending.badFormatedEmail=MailSending.badFormatedEmail + data.records[i].globalStats.badFormatedEmail;
                   }
                   if(data.records[i].globalStats.hardAfter){
                       MailSending.hardAfter=MailSending.hardAfter + data.records[i].globalStats.hardAfter;
                   }
                   if(data.records[i].globalStats.softAfter){
                       MailSending.softAfter=MailSending.softAfter + data.records[i].globalStats.softAfter;
                   }
                   if(data.records[i].globalStats.delivrabilityAfter){
                       MailSending.delivrabilityAfter=MailSending.delivrabilityAfter + data.records[i].globalStats.delivrabilityAfter;
                   }
                   if(data.records[i].globalStats.unsubscribeAfter){
                       MailSending.unsubscribeAfter=MailSending.unsubscribeAfter + data.records[i].globalStats.unsubscribeAfter;
                   }
                   if(data.records[i].globalStats.scompAfter){
                       MailSending.scompAfter = MailSending.scompAfter + data.records[i].globalStats.scompAfter;
                   }
               }
               MailSending.rateRecieved = this.rateCalc(MailSending.volumeRecieved, MailSending.volumeSend);
               MailSending.uniqOpenBySendRate = this.rateCalc(MailSending.uniqOpenBySend, MailSending.volumeRecieved);
               MailSending.uniqClicBySendRate = this.rateCalc(MailSending.uniqClicBySend, MailSending.volumeRecieved);
               MailSending.unsubscribeAfterRate = this.rateCalc(MailSending.unsubscribeAfter, MailSending.volumeRecieved);
               MailSending.logRecieved = MailSending.rateRecieved;
               MailSending.uniqOpenBySendLog = this.logBase(MailSending.uniqOpenBySendRate, 15) * 100;
               MailSending.uniqClicBySendLog = this.logBase(MailSending.uniqClicBySendRate, 2) * 100;
               MailSending.reactivityLog = this.logBase(MailSending.reactivity, 8) * 100;
               MailSending.unsubscribeAfterLog = this.logBase(MailSending.unsubscribeAfterRate, 0.2) * 100;
               MailSending.clicByOpens = (Math.round((MailSending.clic/ MailSending.open)*100)/100);
               MailSending.reactivity = (Math.round(this.rateCalc(MailSending.uniqClicBySend, MailSending.uniqOpenBySend)*100)/100);
               }
            this.emitStatMailSendingSubject(MailSending);
                }
        );
    }
    getUserById(id){
        let urluser = environment.url + environment.version + '/users/'+id+'.json' ;
        return this.http.get(urluser, { headers: new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('currentUser')})
        });
    }
    getMailStat(token) {
        let params = this.paramsStat;
        let url = environment.url + environment.version + '/mails/stats.json' ;
        this.http.get(url, {params, headers: new HttpHeaders({'Authorization': 'Bearer ' + token})
        }).subscribe((data: any[]) => {
                this.emitMailStatSubject(data);
            }
        );

    }
    emitMailStatSubject(data) {
        this.mailStatSubject.next(data);
    }

    logBase(value, rate) {
        var returnValue;
        if (!rate) {
            rate = 1;
        }
        var lvlValue = value/rate;
        if (lvlValue < 1) {
            returnValue = 1-Math.cbrt((rate-value)/rate);
        } else {
            returnValue = Math.cbrt(lvlValue-1) / Math.cbrt(100/rate-1) + 1;
        }
        returnValue /= 2;

        return returnValue;
    }

    getChartData(token) {
        let params = this.paramsChart;
        let url = environment.url + environment.version + '/mails/statsdaybyday.json' ;
        this.http.get(url, {params, headers: new HttpHeaders({'Authorization': 'Bearer ' + token})
        }).subscribe((data: any[]) => {
                this.emitChartSubject(data);
            }
        );
    }
    emitChartSubject(data) {
            this.chartSubject.next(data);
    }
    getStatCmp(){
        let service = this.service;
        if(this.tokens.length != 0){
            if (this.calculateAnnualStat){
                this.tokens.forEach(function (value) {
                    service.getVolumeAnnuel(value);
                    service.getStatMailSending(value);
                    service.getMailStat(value);
                    service.getChartData(value);
                });
            }else {
                this.tokens.forEach(function (value) {
                    service.getStatMailSending(value);
                    service.getMailStat(value);
                    if (service.emitChartStat){
                        service.getChartData(value);
                    }
                });
            }
        }else {
            localStorage.setItem('nodata', 'true');
            this.toastr.error(this.translateService.instant('ACCOUNTS.NODATA'));
        }
    }
}
