<div class="  container" style="padding: 0;">

<div class="clearfix mt-3">
   <h1 class="welcome pull-left mt-0 ng-binding">{{ 'Homepage.HELLO.BFR' | translate }} </h1>
   <div class="float-right bg-white last_connection ng-binding">  {{ 'Homepage.LASTCONNEXION' | translate }} : <strong><span class="ng-binding ng-isolate-scope" *ngIf="languagedate">{{lastloginfr}}</span><span  *ngIf="!languagedate" class="ng-binding ng-isolate-scope">{{lastlogineng}}</span></strong> </div>
</div>
</div>
<div class=" container pt-4" style="padding: 0; margin: 0;">

   <div class="row bg-white pt-4 pl-1 pr1" style="padding: 0; margin: 0;">
    <div class="row mb-3">
        <div class="col-12">
        <div class="tile-header text-center">
            <h1 class="custom-font ng-binding"> <strong class="ml-5 ng-binding">{{ 'Homepage.KPI' | translate }}</strong>  {{'Homepage.THOSELASTDAYS' | translate: '{ days:30 }'}}  </h1>
        </div>
    </div>
    </div>
    <div class="row row  bloc_cmp_header">

        <div class="tile-header">
        <a ui-sref="app.reports.dmp.dashboard" class="btn btn-drank btn-intileheader" routerLink="/reports/cmp"> <span class="fa fa-line-chart" aria-hidden="true" name="line-chart"></span> </a>
            <h1 class="custom-font ng-binding"> <strong class="ml-5 ng-binding">{{ 'Homepage.CMP.HEADER.SHORT' | translate }}</strong>  {{ 'Homepage.CMP.HEADER.LONG' | translate }}</h1>
        </div>
     </div>

   <div class="col-md-4 bloc_stat_vignetee">
       <div class="row mt-3" >   
         <div class="loader_mail" *ngIf="loaderMail">
                <div *ngIf="nodatas == 'true'" class="text-center pt-20 pb-20">{{ 'ACCOUNTS.NODATA' | translate }}</div>      
            <div class="loading-node-svg-container" ng-style="{width: size*20}" *ngIf="nodatas == 'false'">
               <svg class="loading-node-svg loading-node-svg-l" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                  <path class="loading-node-l" d="M104.622,252.056 C111.991,248.159 141.299,229.032 135.218,205.924 C130.632,188.491 71.968,170.057 79.587,119.086 C85.937,76.603 121.263,39.657 121.311,24.107 C87.189,67.257 24.406,102.534 23.955,140.795 C23.234,201.996 64.938,215.733 104.622,252.056 Z"/>
               </svg>
               <svg class="loading-node-svg loading-node-svg-r" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                  <path class="loading-node-r" d="M95.424,40.115 C87.938,44.048 58.202,63.402 64.603,87.127 C69.433,105.023 129.439,124.390 122.103,176.547 C115.989,220.019 80.267,257.579 80.352,273.510 C114.793,229.583 178.539,193.957 178.671,154.762 C178.883,92.068 136.219,77.653 95.424,40.115 Z"/>
               </svg>
            </div>
         </div>
         <div class="col-lg-12 col-md-12 col-sm-12">
               <div class="stat-sending__data-block bg-green bg">
                  <span class="stat-sending__data-block__back-icon hub-recieved">
                  </span>
                   <div class="stat-sending__data-block__content">
                       <div class="stat-sending__data--fixed ng-binding">
                           {{ (StatMails.NbCampagne | number:'':format) || '0' }}
                       </div>
                       <div class="stat-sending__data-block__title ng-binding">
                           {{ 'Reports.CMP.DASHBOARD.GRAPH.CAMPAGNECOMPLETED' | translate }}

                       </div>
                   </div>
               </div>
           </div>
           <div class="col-lg-6">
               <div class="stat-sending__data-block bg-drank bg">
                   <span class="stat-sending__data-block__back-icon hub-sended"></span>
                   <div class="stat-sending__data-block__content">
                       <div class="stat-sending__data--fixed ng-binding">
                           {{ (StatMails.nbSend | number:'':format) || '0' }}
                       </div>
                       <div class="stat-sending__data-block__title ng-binding">

                        {{ 'Reports.CMP.DASHBOARD.GRAPH.SENDEMAIL' | translate}}

                       </div>
                   </div>
               </div>
           </div>
           <div class="col-lg-6">
               <div class="stat-sending__data-block bg-green bg">
                   <span class="stat-sending__data-block__back-icon hub-recieved"></span>
                   <div class="stat-sending__data-block__content">
                       <div class="stat-sending__data--fixed ng-binding">
                           {{ (StatMails.nbDelivred | number:'':format) || '0' }}
                       </div>
                       <div class="stat-sending__data-block__title ng-binding">
                        {{ 'Reports.CMP.DASHBOARD.GRAPH.RECIEVEDEMAIL' | translate}}

                       </div>
                       <div class="stat-sending__data--percent ng-binding">
                           {{ (StatMails.nbDelivred / StatMails.nbSend * 100 | number: '1.2-2':format) || '0' }}%
                           <span class="stat-sending__data--percentof">{{ 'Reports.CMP.SENDING.STATS.OFSENDEDVOLUME' | translate }}</span>
                       </div>
                   </div>
               </div>
           </div>
           <div class="col-lg-6">
               <div class="stat-sending__data-block stat-sending__data-block--small bg bg-orange" ng-show="allSendingsSupportType === 1">
                   <span class="stat-sending__data-block__back-icon hub-opener"></span>
                   <div class="stat-sending__data-block__content">
                       <div class="stat-sending__data--fixed ng-binding">
                           {{ (StatMails.nbUniqOpen | number:'':format) || '0' }}
                       </div>
                       <div class="stat-sending__data-block__title ng-binding">
                           {{ 'Reports.CMP.DASHBOARD.GRAPH.OPENERS' | translate }}

                       </div>
                       <div class="stat-sending__data--percent ng-binding">
                           {{ (StatMails.nbUniqOpen / StatMails.nbDelivred * 100 | number: '1.2-2':format) || '0' }}%
                           <span class="stat-sending__data--percentof">{{ 'Reports.CMP.SENDING.STATS.OFRECIEVERS' | translate }}</span>
                       </div>
                   </div>
               </div>
           </div>
           <div class="col-lg-6">
               <div class="stat-sending__data-block stat-sending__data-block--small bg bg-orange lter" ng-show="allSendingsSupportType === 1">
                   <span class="stat-sending__data-block__back-icon hub-open"></span>
                   <div class="stat-sending__data-block__content">
                       <div class="stat-sending__data--fixed ng-binding">
                           {{ (StatMails.nbOpen | number:'':format) || '0' }}
                       </div>
                       <div class="stat-sending__data-block__title ng-binding">
                           {{ 'Reports.CMP.DASHBOARD.GRAPH.OPENS' | translate }}

                       </div>
                       <div class="stat-sending__data--percent ng-binding">
                           {{ (StatMails.nbOpen / StatMails.nbUniqOpen | number:'':format) || '0' }}
                           <span class="stat-sending__data--percentof ng-binding">
                           {{ 'Reports.CMP.SENDING.STATS.AVERAGEOPEN' | translate }}
                        </span>
                       </div>
                   </div>
               </div>
           </div>
           <div class="col-lg-6">
               <div class="stat-sending__data-block stat-sending__data-block--small bg bg-blue">
                   <span class="stat-sending__data-block__back-icon hub-clicker"></span>
                   <div class="stat-sending__data-block__content">
                       <div class="stat-sending__data--fixed ng-binding">
                           {{ (StatMails.nbUniqClic |number:'':format )|| '0' }}
                       </div>
                       <div class="stat-sending__data-block__title ng-binding">
                           {{ 'Reports.CMP.DASHBOARD.GRAPH.CLICKERS' | translate }}
                       </div>
                       <div class="stat-sending__data--percent ng-binding">
                           {{ (StatMails.nbUniqClic / StatMails.nbDelivred * 100 | number: '1.2-2':format) || '0' }}%
                           <span class="stat-sending__data--percentof">{{ 'Reports.CMP.SENDING.STATS.OFRECIEVERS' | translate }}</span>
                       </div>
                   </div>
               </div>
           </div>
           <div class="col-lg-6">
               <div class="stat-sending__data-block stat-sending__data-block--small bg bg-blue lter">
                   <span class="stat-sending__data-block__back-icon hub-click"></span>
                   <div class="stat-sending__data-block__content">
                       <div class="stat-sending__data--fixed ng-binding">
                           {{ (StatMails.nbClic | number:'':format) || '0' }}
                       </div>
                       <div class="stat-sending__data-block__title ng-binding">
                           {{ 'Reports.CMP.DASHBOARD.GRAPH.CLICKS' | translate }}
                       </div>
                       <div class="stat-sending__data--percent ng-binding">
                           {{ (StatMails.nbClic / StatMails.nbUniqClic | number:'':format) || '0' }}
                           <span class="stat-sending__data--percentof">{{ 'Reports.CMP.SENDING.STATS.AVERAGECLICK' | translate }}</span>
                       </div>
                   </div>
               </div>
           </div>
       </div>

   </div>
   <div class="col-md-8">
      <div class="table-responsive table-widthmax mt-3">        
          <div class="row">          
        <div class="loader_tab_mail" *ngIf="MailSending.length == 0">
                <div *ngIf="nodatas == 'true'" class="text-center pt-20 pb-20">{{ 'ACCOUNTS.NODATA' | translate }}</div> 
            <div class="loading-node-svg-container" ng-style="{width: size*20}" *ngIf="nodatas == 'false'">
               <svg class="loading-node-svg loading-node-svg-l" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                  <path class="loading-node-l" d="M104.622,252.056 C111.991,248.159 141.299,229.032 135.218,205.924 C130.632,188.491 71.968,170.057 79.587,119.086 C85.937,76.603 121.263,39.657 121.311,24.107 C87.189,67.257 24.406,102.534 23.955,140.795 C23.234,201.996 64.938,215.733 104.622,252.056 Z"/>
               </svg>
               <svg class="loading-node-svg loading-node-svg-r" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                  <path class="loading-node-r" d="M95.424,40.115 C87.938,44.048 58.202,63.402 64.603,87.127 C69.433,105.023 129.439,124.390 122.103,176.547 C115.989,220.019 80.267,257.579 80.352,273.510 C114.793,229.583 178.539,193.957 178.671,154.762 C178.883,92.068 136.219,77.653 95.424,40.115 Z"/>
               </svg>
            </div>
         </div>

    
              <div class="table-responsive table-widthmax ">
                  <table class="table table-hover  table-bordered">
                      <thead>
                      <tr>
                          <th class="report-sending__column__type"></th>
                          <th class="report-sending__column__volume"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.ACCOUNT' | translate }}</strong></th>
                          <th class="report-sending__column__date"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.VOLUME' | translate }}</strong></th>
                          <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.VOLUMERECIEVED' | translate }}</strong></th>
                          <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.UNIQOPEN' | translate }}</strong></th>
                          <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.UNIQCLIC' | translate }}</strong></th>
                          <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.REACTIVITY' | translate }}</strong></th>
                          <th class="report-sending__column__data"><strong>{{ 'Reports.CMP.DASHBOARD.LIST.TABLE.UNSUBSCRIBE' | translate }}</strong></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let sending of MailSending.slice(0,limitDataStat)">
                        <th scope="row">
                            <div class="p-5 text-center iconeenvelope" [ngClass]="">
                                <i class="fa fa-envelope-o" ></i>
                            </div>
                        </th>
                        <td class="variable-width-cell" uib-tooltip-template="'campagneThemeTooltip'" tooltip-enable="sending.campagne || sending.theme" tooltip-placement="bottom">
                            {{sending .compte}}
                        </td>

                        <td class="report-sending__column__volume">{{ (sending.volumeSend | number:'':format) || '0'}}</td>
                        <td class="report-sending__column__data">
                            <div class="progress" style="position: relative;">
                                <div class="progress-bar " role="progressbar" style="width: {{ (sending.rateRecieved ) || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                    <div class="stat_nbre">
                                        <span class="text-span-progressbar">{{ (sending.volumeRecieved | number:'':format) || '0'}}</span>
                                        <span class="text-sm"> |{{ (sending.rateRecieved | number: '1.1-2':format) || '0'}}%</span>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="report-sending__column__data">
                            <div class="progress" style="position: relative;">
                                <div class="progress-bar " role="progressbar" style="width:{{ (sending.uniqOpenBySendRate)  || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                    <div class="stat_nbre">
                                        <span class="text-span-progressbar">{{ (sending.uniqOpenBySend | number:'':format) || '0'}}</span>
                                        <span class="text-sm"> |{{ (sending.uniqOpenBySendRate | number: '1.1-2':format) || '0'}}%</span>
                                    </div>
                                </div>
                            </div>

                        </td>
                        <td class="report-sending__column__data">
                            <div class="progress" style="position: relative;">
                                <div class="progress-bar " role="progressbar" style="width: {{ (sending.uniqClicBySendRate) || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                    <div class="stat_nbre">
                                        <span class="text-span-progressbar">{{ (sending.uniqClicBySend | number:'':format) || '0'}}</span>
                                        <span class="text-sm"> |{{ (sending.uniqClicBySendRate | number: '1.1-2':format) || '0'}}%</span>
                                    </div>
                                </div>
                            </div>

                        </td>
                        <td class="report-sending__column__data">
                            <div class="progress" style="position: relative;">
                                <div class="progress-bar " role="progressbar" style="width: {{ (sending.reactivity ) || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                    <div class="stat_nbre">
                                        <span>{{ (sending.reactivity | number:'':format) || '0'}}%</span>
                                    </div>
                                </div>
                            </div>

                        </td>
                        <td class="report-sending__column__data">
                            <div class="progress" style="position: relative;">
                                <div class="progress-bar " role="progressbar" style="width: {{ (sending.unsubscribeAfterRate ) || '0'}}%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">
                                    <div class="stat_nbre">
                                        <span class="text-span-progressbar">{{ (sending.unsubscribeAfter | number:'':format) || '0'}}</span> 
                                    <span class="text-sm"> |{{ (sending.unsubscribeAfterRate | number: '1.1-2':format) || '0'}}%</span>
                                </div>
                            </div>
                            </div>
                        </td>
                    </tr>

                    </tbody>
                  </table>

              </div>
              <button type="button" [hidden]="MailSending.length < 10"  class="btn btn-secondary btn-lg btn-block" (click)="limitdatarows()">{{ 'Homepage.SHOW' | translate }} +</button>

          </div>

      </div>
   </div>
</div>
<div class="row bg-white mt-4 pt-3 " style="position: relative; padding: 0;margin: 0;">
    <div class="clearfix dvd dvd-btm mb-2 ml-1 col-12">
                <h1 class="custom-font ng-binding" style="text-align: center;"> <strong>{{ 'Menu.ACCOUNT' | translate }}  </strong>  </h1>
    </div>
             <div class="loader_tab_account" *ngIf="datas.length==0 && dataShowComptes==false">
                <div class="loading-node-svg-container" ng-style="{width: size*20}">
                   <svg class="loading-node-svg loading-node-svg-l" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                      <path class="loading-node-l" d="M104.622,252.056 C111.991,248.159 141.299,229.032 135.218,205.924 C130.632,188.491 71.968,170.057 79.587,119.086 C85.937,76.603 121.263,39.657 121.311,24.107 C87.189,67.257 24.406,102.534 23.955,140.795 C23.234,201.996 64.938,215.733 104.622,252.056 Z"/>
                   </svg>
                   <svg class="loading-node-svg loading-node-svg-r" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid" viewBox="0 0 190 294">
                      <path class="loading-node-r" d="M95.424,40.115 C87.938,44.048 58.202,63.402 64.603,87.127 C69.433,105.023 129.439,124.390 122.103,176.547 C115.989,220.019 80.267,257.579 80.352,273.510 C114.793,229.583 178.539,193.957 178.671,154.762 C178.883,92.068 136.219,77.653 95.424,40.115 Z"/>
                   </svg>
                </div>
             </div>

   <div class="col-md-12 table-widthmax">
        <div class="form-group form-inline pull-left">
                {{ 'ACCOUNTS.Search' | translate }}: <input class="form-control ml-2" type="text" placeholder="email" [(ngModel)]="getParams.searchValue" (keyup)="pageChanged();ajaxSendings()"/>
          </div>
          <div class="form-group form-inline pull-right">
             <label for="">{{ 'Homepage.DISPLAYED' | translate }} :</label>
             <select class="form-control" #selectElem (change)="handlePageSizeChange($event);ajaxSendings()">
                <option [value]="i" *ngFor="let i of displayedItemsOptions">{{ i }}</option>
                </select>
          </div>
    <table class="table table-hover table-bordered">
        <thead>
         <tr>
           <th class="db-single__row__left db-single__row__actions"></th>


           <th>
             <strong>{{ 'ACCOUNTS.NAME' | translate }}</strong>
           </th>
           <th>
             <strong>{{ 'ACCOUNTS.LASTNAME' | translate }}</strong>
           </th>
           <th>
             <strong>{{ 'ACCOUNTS.FIRSTNAME' | translate }}</strong>
           </th>
           <th>
             <strong>{{ 'ACCOUNTS.USERNAME' | translate }}</strong>
           </th>
           <th>
             <strong>{{ 'ACCOUNTS.EMAIL' | translate }}</strong>
           </th>
           <th>
             <strong>{{ 'ACCOUNTS.TEL' | translate }}</strong>
           </th>
           <th>
            <strong>{{ 'ACCOUNTS.ACTIVE' | translate }}</strong>
           </th>
           <th>
             <strong>{{ 'ACCOUNTS.DATE' | translate }}</strong>
           </th>
         </tr>
         </thead>
         <tbody >
         <tr class="" *ngFor="let account of datas | paginate: {id : 'list',itemsPerPage: getParams.limit,
         currentPage: page , totalItems: totalRecords };" >

            <td >
                <a class="curser" (click)="onSelect(account.id)"><i  class="text-drank fa fa-sign-in"></i></a>
             </td>
           
           <td class="comptename"> {{ account?.name}}</td>
           <td>
             {{ account.firstname }}
           </td>
           <td>
             {{ account.lastname }}
           </td>
           <td>
             {{ account.username }}
           </td>
           <td>
             {{ account.email }}
           </td>
           <td>
             {{ account.phone }}
           </td>
           <td>
               <i class="fa fa-circle" [style.color]="color(account.enabled)"></i>
           </td>
           <td>
             {{ account.last_login | date:'short' }}
           </td>
         </tr>
         </tbody>
       </table>
       <div *ngIf="datas?.length === 0" class="text-center pt-20 pb-20">{{ 'ACCOUNTS.BADSEARCH' | translate }}</div>
       <div class="text-center p-20">
         <div class="btn-group text-drank">
           <pagination-controls  id="list" (pageChange)="handlePageChange($event)" directionLinks="false"></pagination-controls>
         </div>
       </div>
      
   </div>

</div>
</div>