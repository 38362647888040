<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
<div class="page page-homepage " style="margin-top: 20px;" ng-intro="homepageIntroOptions" ng-intro-method="homepageIntroRun">
   <div class="container bg-white pt-3 " >
      <div class="row">
         <div class="col-12  " style="text-align: center;">
            <h3 class="custom-font" translate="Menu.ACCOUNT"></h3>
         </div>
      </div>
      <div class="tile-body table-widthmax">
         <div>
            <div class="form-group form-inline pull-left">
                  {{ 'ACCOUNTS.Search' | translate }}: <input class="form-control ml-2" type="text" placeholder="email" [(ngModel)]="getParams.searchValue" (keyup)="pageChanged($event);ajaxSendings()"/>
            </div>
            <div class="form-group form-inline pull-right">
               <label for="">{{ 'Homepage.DISPLAYED' | translate }} :</label>
               <select class="form-control" #selectElem (change)="handlePageSizeChange($event);ajaxSendings()" >
                  <option [ngValue]="i" *ngFor="let i of displayedItemsOptions">{{ i }}</option>
                  </select>
            </div>
            <table class="table table-hover  table-bordered">
               <thead>
                  <tr>
                     <th class="db-single__row__left db-single__row__actions"></th>
                     <th>
                        <strong>{{ 'ACCOUNTS.NAME' | translate }}</strong>
                      </th>
                      <th>
                        <strong>{{ 'ACCOUNTS.LASTNAME' | translate }}</strong>
                      </th>
                      <th>
                        <strong>{{ 'ACCOUNTS.FIRSTNAME' | translate }}</strong>
                      </th>
                      <th>
                        <strong>{{ 'ACCOUNTS.USERNAME' | translate }}</strong>
                      </th>
                      <th>
                        <strong>{{ 'ACCOUNTS.EMAIL' | translate }}</strong>
                      </th>
                      <th>
                        <strong>{{ 'ACCOUNTS.TEL' | translate }}</strong>
                      </th>
                      <th>
                       <strong>{{ 'ACCOUNTS.ACTIVE' | translate }}</strong>
                      </th>
                      <th>
                        <strong>{{ 'ACCOUNTS.DATE' | translate }}</strong>
                      </th>
                    </tr>
               </thead>
               <tbody >
                  <tr *ngFor="let account of usersdata | paginate: {id : 'list',itemsPerPage: getParams.limit,
                  currentPage: page , totalItems: totalRecords }; let i=index;" (change)="ajaxSendings()" >
                     <td>
                        <a class="text-info curser"  (click)="onSelect(account.id)" ><i  class="text-drank fa fa-sign-in"></i></a>
               
                        <a class="text-info" style="padding-left: 10px" [routerLink]="['/accounts/edit', account.id]"><i  class="text-drank fa fa-pencil"></i></a>
                     </td>
                     <td class="comptename"> {{ account?.name}}</td>
                     <td>
                        {{ account.firstname }}
                     </td>
                     <td>
                        {{ account.lastname }}
                     </td>
                     <td>
                        {{ account.username }}
                     </td>
                     <td>
                        {{ account.email }}
                     </td>
                     <td>
                        {{ account.phone }}
                     </td>
                     <td>
                        <i class="fa fa-circle" [style.color]="color(account.enabled)"></i>
                     </td>
                     <td>
                        {{ account.last_login | date:'short' }}
                     </td>
                  </tr>
               </tbody>

            </table>
            <div *ngIf="usersdata?.length === 0" class="text-center pt-20 pb-20">{{ 'ACCOUNTS.BADSEARCH' | translate }}</div>
            <div class="text-center p-20">
              <div class="btn-group text-drank">
                  <pagination-controls   id="list" (pageChange)="handlePageChange($event)"  directionLinks="false"></pagination-controls>
              </div>
            </div>
            
              
         </div>
         <div>
         </div>
      </div>

   </div>
</div>
<div class="mt-4">
   <input type="button" class="btn btn-drank btn-lg pull-right" [routerLink]="['/accounts/add']" value="{{ 'ACCOUNTS.ADDBTN' | translate }}">
</div>
