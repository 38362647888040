import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import { environment } from '../../../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import { LoginService } from '../login/login.service';


@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  public token =localStorage.getItem('currentUser');
  public dataUser = [];
  public params: HttpParams;
  constructor(private http: HttpClient,private login : LoginService) {
     
   }
   
  getDataAccounts(data){  
      let url = environment.url + environment.version + '/agencies/'+data['agency'].id+'/users.json' ;   
      return this.http.get(url, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})})
  }
  getAnnounces(data){  
    let url = environment.url + environment.version + '/agencies/'+data['agency'].id+'/account.json' ;   
    return this.http.get(url, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})})
  }
  getLastConnectedUser(data){ 
    let params = this.params; 
    let url = environment.url + environment.version + '/lasts/'+data['agency'].id+'/connected/user.json' ;   
    return this.http.get(url,{ params,headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})})
  }
  getLastConnectedUserAnnouncer(data){ 
    let params = this.params; 
    let url = environment.url + environment.version + '/lasts/'+data['agency'].id+'/connected/user/by/announcer.json' ;   
    return this.http.get(url,{ params,headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})})
  }
  getAllCompte(){  
    let url = environment.url + environment.version + '/comptes.json' ;   
    return this.http.get(url, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})
    });
  }
  getAllRoles(){  
    let url = environment.url + environment.version + '/all/roles.json' ;   
    return this.http.get(url, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})
    });
  }
  getUserRoles(userId){  
    let url = environment.url + environment.version + '/user/roles.json'+ '?userId=' + userId ;   
    return this.http.get(url, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})
    });
  }
  getUserById(id){     
    let urluser = environment.url + environment.version + '/users/'+id+'.json' ;
    return this.http.get(urluser, { headers: new HttpHeaders({'Authorization':'Bearer ' + this.token})    
  });
  }  
  update(id, data) {
    let url = environment.url + environment.version + '/users/'+id+'.json';
    return this.http.patch(url,data, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})
  });
}
post(data) {
  let url = environment.url + environment.version + '/users.json';
  return this.http.post(url,data, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})
});
}
postRoles(userId,userRoles,overwrite) {
  //let overwrite =  0;
  let url = environment.url + environment.version + '/users/' + userId + '/roles.json'+'?overwrite=' + overwrite;
  return this.http.post(url,userRoles, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})
});
}
delete(id) {
  let url = environment.url + environment.version + '/users/'+id+'.json';
  return this.http.delete(url, { headers: new HttpHeaders({'Authorization': 'Bearer ' + this.token})
});
}

}
