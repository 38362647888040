import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ReportService } from '../../../services/api/report/report.service';
import {FormControl, FormGroup} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {AccountsService} from '../../../services/api/accounts/accounts.service';
import { AngularCsv } from 'angular7-csv/dist/Angular-csv'
import {$} from 'protractor';
import {of, Subscription} from 'rxjs';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { HttpParams} from '@angular/common/http';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import {
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter,
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import en from '@angular/common/locales/en';
import fr from '@angular/common/locales/fr';

@Component({
    selector: 'app-cmp',
    templateUrl: './cmp.component.html',
    styleUrls: ['./cmp.component.scss'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('language')},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ],
})
export class CmpComponent implements OnInit, OnDestroy{
    subscription: Subscription ;
    test = this.translate.instant('Raport.CMP.COMPTE');
    noAnnounce = localStorage.getItem('nodata');
    nodatas = 'false' ;
    volumeAnnuelsTotal = [];
    volumeAnnuels = [];
    MailSending = [];
    chartDatas = [];
    chartData = [];
    AnnuelSubscription: Subscription;
    StatMailSending: Subscription;
    ChartData: Subscription;
    number_iteration=1;
    StatMail: Subscription;
    StatMails = {
        "nbOpen":0,
        "nbClic":0,
        "nbUniqOpen":0,
        "nbUniqClic":0,
        "nbSend":0,
        "nbDelivred":0,
        "NbCampagne":0
    };
    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
    });
    displayedItemsOptions = [5, 10, 25, 50];
    getParams = {
        limit: 5,
        type: 'classic',
        supportType: '1',
        dateStart: '',
        dateEnd: '',
        order: 'DESC',
        archive: 0,
        test: 0,
        orderBy: 'dateSend'
    };
    lineChartData: ChartDataSets [] = [];

    lineChartLabels: Label[] = [];

    lineChartOptions = {
        responsive: true,
        legend: {
            labels: {
                usePointStyle: true,
            },
            position: 'top',
            align: 'end'
        },
        elements: {
            line: {
                tension: 0.4,
                borderWidth: 2,
            },
            point: {
                radius: 0
            }
        },
        tooltips: {
            intersect: false
        },
        scales: {}
    };
    lineChartColors: Color[] = [];
    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';
    dateStart = "";
    dateEnd = "";
    name: string;
    format = '';
    first = true;
    constructor(private reportService: ReportService, private authentificationService: AuthService, private accountService: AccountsService, private translate: TranslateService, public datepipe: DatePipe, private _adapter: DateAdapter<any>) { }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            if(event.lang=="en"){
                this.format = 'en';
            }
            else{
                this.format = 'fr';
            }
        });
        this.reportService.calculateAnnualStat = true;
        this.reportService.emitChartStat = true;
        var today = new Date();
        var lastMonth = new Date(today.getFullYear(),today.getMonth(),0);
        lastMonth.setDate(Math.min(today.getDate(),lastMonth.getDate()));
        if (localStorage.getItem('language') == 'en'){
            this.dateStart = this.datepipe.transform(lastMonth,'MM/dd/yyyy');
            this.dateEnd = this.datepipe.transform(today,'MM/dd/yyyy');
        }else{
            this.dateStart = this.datepipe.transform(lastMonth,'dd/MM/yyyy');
            this.dateEnd = this.datepipe.transform(today,'dd/MM/yyyy');
        }
        this.reportService.getToken();
        this.ajaxSendings();
    }
    rateCalc(value, on) {
        let result;
        result = value / on * 100;
        if (isNaN(result)) {
            result = 0;
        }
        return result;
    };

    sommeAnuel(volumeannuel){
        let index = this.volumeAnnuelsTotal[volumeannuel.year];
        if (index != undefined ) {
            this.volumeAnnuelsTotal[volumeannuel.year].volume = this.volumeAnnuelsTotal[volumeannuel.year].volume + volumeannuel.volume;
            this.volumeAnnuelsTotal[volumeannuel.year].year = volumeannuel.year;
            this.volumeAnnuelsTotal[volumeannuel.year].volumeRecieved = this.volumeAnnuelsTotal[volumeannuel.year].volumeRecieved + volumeannuel.volumeRecieved;
            this.volumeAnnuelsTotal[volumeannuel.year].volumeSend = this.volumeAnnuelsTotal[volumeannuel.year].volumeSend + volumeannuel.volumeSend;
            this.volumeAnnuelsTotal[volumeannuel.year].volumesms = this.volumeAnnuelsTotal[volumeannuel.year].volumesms + volumeannuel.volumesms;
            this.volumeAnnuelsTotal[volumeannuel.year].uniqopenbysend = this.volumeAnnuelsTotal[volumeannuel.year].uniqopenbysend + volumeannuel.uniqopenbysend;
            this.volumeAnnuelsTotal[volumeannuel.year].uniqclicbysend = this.volumeAnnuelsTotal[volumeannuel.year].uniqclicbysend + volumeannuel.uniqclicbysend;

        }else {
            this.volumeAnnuelsTotal[volumeannuel.year] = volumeannuel;
        }
        this.volumeAnnuels=[];
        this.volumeAnnuelsTotal.forEach(value => {
            value.clickerRate = this.rateCalc(value.uniqclicbysend, value.volumeRecieved);
            value.openerRate = this.rateCalc(value.uniqopenbysend, value.volumeRecieved);
            value.reactivityRate = this.rateCalc(value.uniqclicbysend, value.uniqopenbysend);
            value.recievedRate = this.rateCalc(value.volumeRecieved, value.volumeSend);
            this.volumeAnnuels.push(value);
        });

    }
    sommeChartData(data, chartDatas){
        let found = chartDatas[data.ddate.split("-").join("")];
        if(found != undefined){

            if(data.nbEmailDelivred){
                this.chartDatas[data.ddate.split("-").join("")].nbEmailDelivred = this.chartDatas[data.ddate.split("-").join("")].nbEmailDelivred + data.nbEmailDelivred;
            }
            if(data.nbEmailSend){
                this.chartDatas[data.ddate.split("-").join("")].nbEmailSend = this.chartDatas[data.ddate.split("-").join("")].nbEmailSend + data.nbEmailSend;
            }
            if(data.nbEmailOpen){
                this.chartDatas[data.ddate.split("-").join("")].nbEmailOpen = this.chartDatas[data.ddate.split("-").join("")].nbEmailOpen + data.nbEmailOpen;
            }
            if(data.nbEmailClic){
                this.chartDatas[data.ddate.split("-").join("")].nbEmailClic = this.chartDatas[data.ddate.split("-").join("")].nbEmailClic + data.nbEmailClic;
            }
            if(data.nbSMSDelivred){
                this.chartDatas[data.ddate.split("-").join("")].nbSMSDelivred = this.chartDatas[data.ddate.split("-").join("")].nbSMSDelivred + data.nbSMSDelivred;
            }
            if(data.nbSMSSend){
                this.chartDatas[data.ddate.split("-").join("")].nbSMSSend = this.chartDatas[data.ddate.split("-").join("")].nbSMSSend + data.nbSMSSend;
            }
            if(data.nbSMSClic){
                this.chartDatas[data.ddate.split("-").join("")].nbSMSClic = this.chartDatas[data.ddate.split("-").join("")].nbSMSClic + data.nbSMSClic;
            }
            if(data.nbSMSOpen){
                this.chartDatas[data.ddate.split("-").join("")].nbSMSOpen = this.chartDatas[data.ddate.split("-").join("")].nbSMSOpen + data.nbSMSOpen;
            }
        }

        this.chartData = [];
        if(chartDatas.length){
            chartDatas.forEach(value => {
                this.chartData.push(value);
            });
        }
    }
    sommeChartNoData(chartDatas){
        this.chartData = [];
        if (chartDatas.length){
            chartDatas.forEach(value => {
                this.chartData.push(value);
            });
        }
    }
    ajaxSendings() {
        this._adapter.setLocale(localStorage.getItem('language'));
        this.MailSending = [];
        this.chartData = [];
        this.chartDatas = [];
        this.StatMails = {
            "nbOpen":0,
            "nbClic":0,
            "nbUniqOpen":0,
            "nbUniqClic":0,
            "nbSend":0,
            "nbDelivred":0,
            "NbCampagne":0
        };
        if (this.reportService.calculateAnnualStat){
            this.volumeAnnuelsTotal = [];
            this.volumeAnnuels = [];
            this.reportService.annuelDataSubject();
        }
        this.reportService.statMailSendingSubject();
        this.reportService.statMailSubject();
        this.reportService.chartDataSubject();
        let params = new HttpParams();
        params=params.set('type', this.getParams.type);
        params=params.set('supportType', this.getParams.supportType);
        let paramsMailSending = params;
        if (this.range.value.start && this.range.value.end){
            params=params.set('dateStart', this.datepipe.transform(this.range.value.start,'yyyy-MM-dd'));
            params=params.set('dateEnd', this.datepipe.transform(this.range.value.end,'yyyy-MM-dd'));
            paramsMailSending=paramsMailSending.set('startDate', this.datepipe.transform(this.range.value.start,'yyyy-MM-dd'));
            paramsMailSending=paramsMailSending.set('endDate', this.datepipe.transform(this.range.value.end,'yyyy-MM-dd'));
            if (localStorage.getItem('language') == 'en'){
                this.dateStart = this.datepipe.transform(this.range.value.start,'MM/dd/yyyy');
                this.dateEnd = this.datepipe.transform( this.range.value.end,'MM/dd/yyyy');
            }else{
                this.dateStart = this.datepipe.transform(this.range.value.start,'dd/MM/yyyy');
                this.dateEnd = this.datepipe.transform( this.range.value.end,'dd/MM/yyyy');
            }

        }else{
            var today = new Date();
            var lastMonth = new Date(today.getFullYear(),today.getMonth(),0);
            lastMonth.setDate(Math.min(today.getDate(),lastMonth.getDate()));
            params=params.set('dateEnd', this.datepipe.transform(today,'yyyy-MM-dd'));
            params=params.set('dateStart', this.datepipe.transform(lastMonth,'yyyy-MM-dd'));
            paramsMailSending=paramsMailSending.set('endDate', this.datepipe.transform(today,'yyyy-MM-dd'));
            paramsMailSending=paramsMailSending.set('startDate', this.datepipe.transform(lastMonth,'yyyy-MM-dd'));
        }
        //paramsMailSending=paramsMailSending.set('limit', '10');
        paramsMailSending=paramsMailSending.set('archive', '0');
        paramsMailSending=paramsMailSending.set('test', '0');
        paramsMailSending=paramsMailSending.set('orderBy', 'dateCreation');
        if(this.getParams.type == 'trigger'){
            paramsMailSending=paramsMailSending.set('onlyApiCall', '0');
        }else if (this.getParams.type == 'apicall') {
            params=params.set('type', 'trigger');
            paramsMailSending=paramsMailSending.set('type', 'trigger');
            paramsMailSending=paramsMailSending.set('onlyApiCall', '1');
        }

        this.reportService.paramsMailSending = paramsMailSending;
        this.reportService.paramsStat = params;
        this.reportService.paramsChart = params;
        if(!this.first){
            this.reportService.getStatCmp();
        }
        this.AnnuelSubscription = this.reportService.annuelSubject.subscribe(
            (volumeAnnuels: any[]) => {
                this.sommeAnuel(volumeAnnuels);
            }
        );

        this.StatMailSending = this.reportService.statMailSending.subscribe(
            (data: any) => {
                this.MailSending.push(data);
            }
        );
        this.StatMail = this.reportService.mailStatSubject.subscribe(
            (data: any) => {
                this.range.reset() ;
                if (data.nbOpen){
                    this.StatMails.nbOpen = this.StatMails.nbOpen + data.nbOpen;
                }
                if (data.nbClic){
                    this.StatMails.nbClic = this.StatMails.nbClic + data.nbClic;
                }
                if (data.nbUniqOpen){
                    this.StatMails.nbUniqOpen = this.StatMails.nbUniqOpen + data.nbUniqOpen;
                }
                if (data.nbUniqClic){
                    this.StatMails.nbUniqClic = this.StatMails.nbUniqClic + data.nbUniqClic;
                }
                if (data.nbSend){
                    this.StatMails.nbSend = this.StatMails.nbSend + data.nbSend;
                }
                if (data.nbDelivred){
                    this.StatMails.nbDelivred = this.StatMails.nbDelivred + data.nbDelivred;
                }
                if (data.NbCampagne){
                    this.StatMails.NbCampagne = this.StatMails.NbCampagne + data.NbCampagne;
                }
            }
        );
        var diffDays = (new Date(paramsMailSending.get('endDate')).getTime() - new Date(paramsMailSending.get('startDate')).getTime())/(1000 * 3600 * 24);
        let firstdate = new Date(paramsMailSending.get('startDate'));
        let yearStart = firstdate.getFullYear();
        let yearEnd = new Date(paramsMailSending.get('endDate')).getFullYear();
        for (var i = 0; i <= diffDays; i++) {
            let baseData = {
                ddate: this.datepipe.transform(firstdate,'yyyy-MM-dd'),
                nbEmailDelivred: 0,
                nbEmailSend: 0,
                nbEmailOpen:0,
                nbEmailClic:0,
                nbSMSDelivred:0,
                nbSMSSend:0,
                nbSMSClic:0,
                nbSMSOpen:0
            };
            this.chartDatas[baseData.ddate.split("-").join("")] = baseData;
            firstdate.setDate(firstdate.getDate() + 1);
        }
        this.ChartData = this.reportService.chartSubject.subscribe(
            (data: any) => {
                if (data.length == 0){
                    this.sommeChartNoData(this.chartDatas);
                }else{
                    for(let i=0;i<data.length;i++){
                        this.sommeChartData(data[i], this.chartDatas);
                    }
                }
                this.lineChartData=[];
                this.lineChartLabels=[];
                if (this.chartData.length){
                    let dataVal={
                        "nbEmailSend":[],
                        "nbEmailDelivred":[],
                        "nbEmailOpen":[],
                        "nbEmailClic":[],
                        "nbSMSSend":[],
                        "nbSMSDelivred":[],
                        "nbSMSClic":[],
                        "nbSMSOpen":[]
                    };
                    this.chartData.forEach(value => {
                        this.lineChartLabels.push(this.datepipe.transform(value.ddate, 'dd/MM/yyyy'));
                        dataVal.nbEmailSend.push(value.nbEmailSend );
                        dataVal.nbEmailDelivred.push(value.nbEmailDelivred );
                        dataVal.nbEmailOpen.push(value.nbEmailOpen );
                        dataVal.nbEmailClic.push(value.nbEmailClic );
                        dataVal.nbSMSSend.push(value.nbSMSSend );
                        dataVal.nbSMSDelivred.push(value.nbSMSDelivred );
                        dataVal.nbSMSClic.push(value.nbSMSClic );
                        dataVal.nbSMSOpen.push(value.nbSMSOpen );
                    });
                    if(this.getParams.supportType === '1'){
                        this.lineChartData.push({
                            "data":dataVal.nbEmailSend,
                            "label": this.translate.instant('Reports.CMP.DASHBOARD.GRAPH.SENDMESSAGE'),
                            "spanGaps": true,
                        });
                        this.lineChartData.push({
                            "data":dataVal.nbEmailDelivred,
                            "label": this.translate.instant('Reports.CMP.DASHBOARD.GRAPH.RECIEVEDMESSAGEL'),
                            "spanGaps": true,
                        });
                        this.lineChartData.push({
                            "data":dataVal.nbEmailOpen,
                            "label": this.translate.instant('Reports.CMP.DASHBOARD.GRAPH.OPENS'),
                            "spanGaps": true,
                        });
                        this.lineChartData.push({
                            "data":dataVal.nbEmailClic,
                            "label": this.translate.instant('Reports.CMP.DASHBOARD.GRAPH.CLICKS'),
                            "spanGaps": true,
                        });
                        this.lineChartColors = [
                            {
                                borderColor:"#5e0468",
                                backgroundColor: "transparent",
                            },
                            {
                                borderColor:"#148932",
                                backgroundColor: "transparent",
                            },
                            {
                                borderColor:"#ffc100",
                                backgroundColor: "transparent",
                            }
                            ,
                            {
                                borderColor:"#418bca",
                                backgroundColor: "transparent",
                            }
                        ];
                    }else {
                        this.lineChartData.push({
                            "data":dataVal.nbSMSSend,
                            "label": this.translate.instant('Reports.CMP.DASHBOARD.GRAPH.SENDSMS'),
                            "spanGaps": true,
                        });
                        this.lineChartData.push({
                            "data":dataVal.nbSMSDelivred,
                            "label": this.translate.instant('Reports.CMP.DASHBOARD.GRAPH.RECIEVEDSMS'),
                            "spanGaps": true,
                        });
                        this.lineChartData.push({
                            "data":dataVal.nbSMSOpen,
                            "label": this.translate.instant('Reports.CMP.DASHBOARD.GRAPH.OPENS'),
                            "spanGaps": true,
                        });
                        this.lineChartData.push({
                            "data":dataVal.nbSMSClic,
                            "label": this.translate.instant('Reports.CMP.DASHBOARD.GRAPH.CLICKS'),
                            "spanGaps": true,
                        });
                        this.lineChartColors = [
                            {
                                borderColor:"#5e0468",
                                backgroundColor: "transparent",
                            },
                            {
                                borderColor:"#148932",
                                backgroundColor: "transparent",
                            },
                            {
                                borderColor:"#ffc100",
                                backgroundColor: "transparent",
                            }
                            ,
                            {
                                borderColor:"#418bca",
                                backgroundColor: "transparent",
                            }
                        ];
                    }
                    this.lineChartOptions.scales = {
                        xAxes: [{
                            ticks: {
                                autoskip: true,
                                autoSkipPadding: 20,
                                maxRotation: 0
                            }
                        }]
                    }
                }
            }
        );
        this.nodatas = this.noAnnounce;
    }

    results(){
        new AngularCsv(this.MailSending, 'My Report',{ fieldSeparator: ';', headers:
                [ this.translate.instant('Raport.CMP.COMPTE'),
                    this.translate.instant('Raport.CMP.VOLUME'),
                    this.translate.instant('Raport.CMP.volumeSend'),
                    this.translate.instant('Raport.CMP.volumeRecieved'),
                    this.translate.instant('Raport.CMP.uniqOpenBySend'),
                    this.translate.instant('Raport.CMP.open'),
                    this.translate.instant('Raport.CMP.uniqClicBySend'),
                    this.translate.instant('Raport.CMP.clic'),
                    this.translate.instant('Raport.CMP.clicByOpens'),
                    this.translate.instant('Raport.CMP.reactivity'),
                    this.translate.instant('Raport.CMP.hardBefore'),
                    this.translate.instant('Raport.CMP.softBefore'),
                    this.translate.instant('Raport.CMP.notOptinBefore'),
                    this.translate.instant('Raport.CMP.badFormatedEmail'),
                    this.translate.instant('Raport.CMP.hardAfter'),
                    this.translate.instant('Raport.CMP.softAfter'),
                    this.translate.instant('Raport.CMP.delivrabilityAfter'),
                    this.translate.instant('Raport.CMP.unsubscribeAfter'),
                    this.translate.instant('Raport.CMP.scompAfter'),
                    this.translate.instant('Raport.CMP.rateRecieved'),
                    this.translate.instant('Raport.CMP.uniqOpenBySendRate'),
                    this.translate.instant('Raport.CMP.uniqClicBySendRate'),
                    this.translate.instant('Raport.CMP.unsubscribeAfterRate'),
                    this.translate.instant('Raport.CMP.logRecieved'),
                    this.translate.instant('Raport.CMP.uniqOpenBySendLog'),
                    this.translate.instant('Raport.CMP.uniqClicBySendLog'),
                    this.translate.instant('Raport.CMP.reactivityLog'),
                    this.translate.instant('Raport.CMP.unsubscribeAfterLog'),
                ]
        });
    }

    formatDate(event){
        if(this.range.value.end == null)
            this.number_iteration=1;
        this.subscription =this.range.valueChanges.subscribe(value => {
            if(value.start!=null && value.end !=null && this.number_iteration==1 ){
                this.number_iteration=2;
                this.ajaxSendings();
            }
        });
    }
    doNotRecalculateAnnualStat(){
        this.reportService.calculateAnnualStat = false;
    }
    notFirst(){
        this.first = false;
    }
    handlePageSizeChange(event): void {
        this.getParams.limit = event.target.value;
    }

    public ngOnDestroy() {
        this.AnnuelSubscription.unsubscribe();
        this.StatMailSending.unsubscribe();
        this.ChartData.unsubscribe();
        this.StatMail.unsubscribe();

    }

}
