import { Component, OnInit, ɵConsole, OnDestroy } from '@angular/core';
import { HomeService } from '../../services/api/home/home.service';
import {AccountsService} from '../../services/api/accounts/accounts.service';
import { LoginService } from '../../services/api/login/login.service';
import {Subscription} from 'rxjs';
import {ReportService} from '../../services/api/report/report.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
import { HttpParams} from '@angular/common/http';
import { formatDate, registerLocaleData  } from '@angular/common';
import * as jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  displayedItemsOptions = [5, 10, 25, 50];
  noAnnounce = localStorage.getItem('nodata');
  nodatas = 'false' ;
  page:number = 1;
    StatMail: Subscription;
    totalRecords:number;
    getParams = {
      order: 'DESC',
      searchField: 'email',
      searchValue:'',
      orderBy: 'dateSend',
      offset:'0',
      limit: '5',
      currentPage:'1'
  };
  limitDataStat=10;
    StatMails = {
        "nbOpen":0,
        "nbClic":0,
        "nbUniqOpen":0,
        "nbUniqClic":0,
        "nbSend":0,
        "nbDelivred":0,
        "NbCampagne":0
    };
    StatMailSending: Subscription;
    MailSending = [];
    loaderMail=true;

    datas = [];
    languagedate=false;

    days=30;
    lastloginfr=  '';
    lastlogineng=  '';
    curentlanguage="";
    dataShowComptes=false;
    format = '';
 constructor( private homeservice: HomeService,private translateService: TranslateService,private account: AccountsService,private login:LoginService,private reportService: ReportService,private toastr: ToastrService) { }
  ngOnInit(): void {
     this.getData();
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if(event.lang=="en"){
          this.languagedate=false;
          this.format = 'en';
      }
      else{
          this.languagedate=true;
          this.format = 'fr';
        }
    });
    this.reportService.calculateAnnualStat = false;
    this.reportService.emitChartStat = false;
    this.reportService.statMailSendingSubject();
    this.reportService.statMailSubject();
    this.curentlanguage=this.translateService.getDefaultLang();
    let params = new HttpParams();
    let paramsStat = new HttpParams();
    var paramsMailSending = params;
  let datetoday=formatDate(new Date(), 'yyyy-MM-dd','en-US');
  let datelastMonthDay=formatDate(new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1, 
    new Date().getDate()), 'yyyy-MM-dd','en-US');
    //paramsMailSending=paramsMailSending.set('limit', '10');
    paramsMailSending=paramsMailSending.set('archive', '0');
    paramsMailSending=paramsMailSending.set('test', '0');
    paramsMailSending=paramsMailSending.set('orderBy', 'dateCreation');   
    paramsMailSending=paramsMailSending.set('type', 'classic');
    paramsMailSending=paramsMailSending.set('limit', '190');
    paramsMailSending=paramsMailSending.set('startDate', datelastMonthDay);
    paramsMailSending=paramsMailSending.set('endDate', datetoday);
    this.reportService.paramsMailSending = paramsMailSending;
    paramsStat = paramsStat.set('supportType','1');
    paramsStat = paramsStat.set('dateStart', datelastMonthDay);
    paramsStat = paramsStat.set('dateEnd', datetoday);
    this.reportService.paramsStat = paramsStat;
      this.StatMailSending = this.reportService.statMailSending.subscribe(
          (data: any) => {
              this.MailSending.push(data);
          }
      );
      this.StatMail = this.reportService.mailStatSubject.subscribe(
          (data: any) => {
              if (data.nbOpen){
                  this.StatMails.nbOpen = this.StatMails.nbOpen + data.nbOpen;
              }
              if (data.nbClic){
                  this.StatMails.nbClic = this.StatMails.nbClic + data.nbClic;
              }
              if (data.nbUniqOpen){
                  this.StatMails.nbUniqOpen = this.StatMails.nbUniqOpen + data.nbUniqOpen;
              }
              if (data.nbUniqClic){
                  this.StatMails.nbUniqClic = this.StatMails.nbUniqClic + data.nbUniqClic;
              }
              if (data.nbSend){
                  this.StatMails.nbSend = this.StatMails.nbSend + data.nbSend;
              }
              if (data.nbDelivred){
                  this.StatMails.nbDelivred = this.StatMails.nbDelivred + data.nbDelivred;
              }
              if (data.NbCampagne){
                  this.StatMails.NbCampagne = this.StatMails.NbCampagne + data.NbCampagne;
              }
          if(this.StatMails.NbCampagne!=0 ||this.StatMails.nbDelivred || this.StatMails.nbSend!=0 || this.StatMails.nbUniqClic!=0 || this.StatMails.nbUniqOpen || this.StatMails.nbClic || this.StatMails.nbOpen)
          this.loaderMail=false;

          }
      );
      this.reportService.getToken();
      this.ajaxSendings();
      this.nodatas=this.noAnnounce;
  }
    rateCalc(value, on) {
        var result;
        result = value / on * 100;
        if (isNaN(result)) {
            result = 0;
        }
        return result;
    };

    ajaxSendings() {
    this.dataShowComptes=true;

      let params = new HttpParams();
      if(this.getParams.searchValue){
        params=params.set('searchField', this.getParams.searchField);
        params=params.set('searchValue', this.getParams.searchValue);
        params=params.set('offset',  this.getParams.offset);
        params=params.set('limit', this.getParams.limit);
      } else {
        params=params.set('limit', this.getParams.limit);
        params=params.set('offset',  ((this.page - 1) * Number(this.getParams.limit)).toString());
      }
      this.login.getUsetInfo().toPromise().then((data: any)=>{
       this.account.params = params;
       this.lastlogineng=formatDate(data.lastLogin, 'MMM d y, hh:mm','en-US');
       this.lastloginfr=formatDate(data.lastLogin, 'd MMM y, hh:mm','fr-FR');
       this.account.getLastConnectedUserAnnouncer(data).subscribe((data: any[])=>{
        this.datas = data['records'];
        this.totalRecords = data['count']
      })
    })
     }


  color(p) {
    if(p==0) {return 'red'}
    if(p==1) {return '#5cb85c'}
    }
  onSelect(userId) {
    this.toastr.warning(this.translateService.instant('Homepage.MSGALERT'));
    window.open(environment.switch + '/#/login?token=' + localStorage.getItem('currentUser') + '&switchuser=' + userId);
  }
  limitdatarows(){
    this.limitDataStat=+10;
  }
  pageChanged(){
    this.page = 1;
    this.ajaxSendings();
  }
  handlePageChange(event): void {
    this.page = event;
    this.ajaxSendings();
  }

  handlePageSizeChange(event): void {
    this.getParams.limit = event.target.value;
    this.page = 1;
    this.ajaxSendings();
  }
  getData(){
    this.login.getUsetInfo().toPromise().then((data: any[])=>{
        this.account.getDataAccounts(data).subscribe((account: any[])=>{
            localStorage.setItem('agencyId', account[0].agency.id);
        })
    });
  }
  public ngOnDestroy() {
     this.StatMailSending.unsubscribe();
     this.StatMail.unsubscribe();
  }


}
