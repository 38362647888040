import { Injectable,Pipe} from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
private logged = false;
  constructor(private http: HttpClient) { }
  isLoggedIn(): boolean {
     if(localStorage.getItem('currentUser'))
         return true;
      else
          return false;
    ;
  }
    login(Username: string, Password: string) {
        return this.http.post<any>(environment.url + `/login_check`, { Username, Password })
            .pipe(map(data => {
                // login successful if there's a jwt token in the response
                if (data && data.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', data.token);
                    localStorage.setItem('nodata', 'false');
                    this.logged = true;
                    this.isLoggedIn();
                }

                return data;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
    
}
