<div class="container block_control_page">
<span class="page-nav__controls ">
  <div class="page-nav__controls-container">
    <div class="page-nav__cell page-nav__previous-btn">
      <div (click)="goBack()" class="btn btn-default previous-btn"><i class="fa fa-arrow-left" aria-hidden="true"></i>
</div>
    </div>
     <div class="page-nav__cell page-nav__next-btn">
      <div (click)="goBack()" class="btn btn-default next-btn"><i class="fa fa-arrow-right"></i></div>
    </div>
     <div class="page-nav__cell page-nav__reload-btn">
      <div (click)="refreshPage()" class="btn btn-default reload-btn"><i class="fa fa-refresh"></i></div>
    </div>
    <div class="page-nav__cell page-nav__breadcrumb">
      <div class="page-bar">
         <ul class="page-breadcrumb">
            <li> <a ui-sref="app.homepage" class="ng-binding homeBar" routerLink="/home"> <i class="fa fa-home"></i> </a> </li>
            <li *ngIf="activedTab == '/home'"> <span class="ng-binding home_title">{{ 'Menu.WELCOME' | translate }}</span> </li>
            <li *ngIf="activedTab == '/reports/cmp'"> <span class="ng-binding home_title">{{ 'Menu.REPORTS' | translate }} > CMP</span> </li>
            <li *ngIf="activedTab == '/accounts'"> <span class="ng-binding home_title">{{ 'Menu.ACCOUNT' | translate }} </span> </li>
         </ul>
      </div>
   </div>
  </div>
</span>

</div>