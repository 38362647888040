import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public token =localStorage.getItem('currentUser');
  constructor(private http: HttpClient) { }

  getUsetInfo(){
    let userData = jwt_decode(this.token);      
    let urluser = environment.url + environment.version + '/users/'+userData.userId+'.json' ;
    return this.http.get(urluser, { headers: new HttpHeaders({'Authorization':'Bearer ' + this.token})    
  });
  }  
}
