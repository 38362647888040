import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './core/_parts/nav/nav.component';
import { HeaderComponent } from './core/_parts/header/header.component';
import { CmpComponent } from './core/report/cmp/cmp.component';
import { LoginComponent } from './core/login/login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AccountsComponent } from './core/accounts/accounts.component';
import { PageControlsComponent } from './core/_parts/page-controls/page-controls.component';
import { HomeComponent } from './core/home/home.component';
import { ErrorInterceptor} from './services/error.interceptor';
import {AuthService} from './services/auth.service';
import {CoreModule} from './core/core.module';
import {CoreComponent} from './core/core/core.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EdituserComponent } from './core/edituser/edituser.component';
import { AdduserComponent} from './core/adduser/adduser.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterPipe} from './filter.pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationDialogComponentComponent} from './confirmation-dialog/confirmation-dialog-component/confirmation-dialog-component.component'
import { from } from 'rxjs';
import { MatDialogModule } from '@angular/material/dialog';
import {CustomDatePipe} from './pipes/customDate.pipe';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NoCommaPipe} from './pipes/noComma.pipe';
import { ChartsModule } from 'ng2-charts';
import { CdkTableExporterModule } from 'cdk-table-exporter';
import { MatTableExporterModule } from 'mat-table-exporter';
import { DatePipe } from '@angular/common';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translation/', '.json');
}
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEN from '@angular/common/locales/en';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
registerLocaleData(localeFr);
registerLocaleData(localeEN);

@NgModule({
  declarations: [
    AppComponent,
    CmpComponent,
    NavComponent,
    HeaderComponent,
    LoginComponent,
    AccountsComponent,
    PageControlsComponent,
    HomeComponent,
    CoreComponent,
    CmpComponent,
    PageControlsComponent,
    EdituserComponent,
    AdduserComponent,
    FilterPipe,
    ConfirmationDialogComponentComponent,
    NoCommaPipe,
    CustomDatePipe,
  ],
  imports: [
    BrowserModule,
    NgbCollapseModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxPaginationModule,
    MatDialogModule,
    CdkTableExporterModule,
    MatTableExporterModule,

    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-top-right'
    }),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    CoreModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    ChartsModule,
    Ng2SearchPipeModule,
    NgbModule,
  ],

  providers: [{provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}, AuthService, DatePipe,
    { provide: LOCALE_ID, useValue: localStorage.getItem('language')},
  ],
  exports: [
    PageControlsComponent, NoCommaPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
