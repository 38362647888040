import { NgModule } from '@angular/core';
import {AccountsComponent } from './core/accounts/accounts.component';
import {HomeComponent} from './core/home/home.component';
import {RouterModule, Routes} from '@angular/router';

import { LoginComponent } from './core/login/login.component';
import { AuthService } from './services/auth.service';
import {AuthGuard} from './services/auth.guard';


const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService]
})
export class AppRoutingModule { }
