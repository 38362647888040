import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './home/home.component';
import {AccountsComponent} from './accounts/accounts.component';
import {CmpComponent } from './report/cmp/cmp.component';
import {AuthGuard} from '../services/auth.guard';
import {AuthService} from '../services/auth.service';
import {CoreComponent} from './core/core.component';
import { EdituserComponent } from './edituser/edituser.component';
import { AdduserComponent } from './adduser/adduser.component';

const routes: Routes = [
    { path: '', component:CoreComponent,
        canActivate: [AuthGuard],
        children: [
            { path: 'home', component: HomeComponent },
            { path: 'accounts', component: AccountsComponent },
            { path: 'reports/cmp', component: CmpComponent },
            { path: 'accounts/edit/:id', component: EdituserComponent },
            { path: 'accounts/add', component: AdduserComponent }
        ]
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthService]
})
export class CoreRoutingModule { }

