import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';
  constructor(private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private router: Router,private toastr: ToastrService,
              private authenticationService: AuthService) { }
  ngOnInit() {
      this.loginForm = this.formBuilder.group({
          Username: ['', Validators.required],
          Password: ['', Validators.required]
      });
  }
  get f() { return this.loginForm.controls; }
  login() {
      this.submitted = true;
      if (this.loginForm.invalid) {
          return;
      }

      this.loading = true;
      this.authenticationService.login(this.f.Username.value, this.f.Password.value)
          .subscribe(
              data => {
                let userData = jwt_decode(data.token);
              if(userData.roles.indexOf("ROLE_AGENCY") != -1){
                  this.router.navigate(['/home']);
                  this.toastr.success(this.translateService.instant('Login.CONNECT'));
              }
              else
                  this.toastr.error(this.translateService.instant('Login.REFUS'));
              },
              error => {
                  this.toastr.error(this.translateService.instant('Login.ERROR'));

              });
  }
}
