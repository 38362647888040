<nav class="navbar navbar-expand-lg navbar-light bg-white">
  <a class="navbar-brand" href="#"><img src="assets/images/logo.png" alt="HubScore" id="logo"></a>

  
  <div class="collapse navbar-collapse show " id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto nameacount">
      <li class="nav-item active">
        {{ userInfos['agency']?.name}}
      </li>

    </ul>
    <ul class="block_right navbar-nav ">

      <li class="nav-item dropdown " ngbDropdown>
        <a class="nav-link "ngbDropdownToggle  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-user fa-fw"></i>{{userInfos['firstName']}} {{userInfos['lastName']}}
        </a>
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="navbarDropdown">
          <div class="dropdown-divider"></div>
          <a id="headerLogoutButton" href="#" (click)="logout(true)" class="dropdown-item"> <span class="fa fa-sign-out fa-fw" aria-hidden="true" fw="" name="sign-out"></span> {{ 'Menu.LOGOUT' | translate }} </a> 
        </div>
      </li>
      <li class="nav-item dropdown " ngbDropdown>
        <a class="nav-link dropdownDrapo " ngbDropdownToggle  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img src="assets/images/en.png" [hidden]="language =='fr'" alt="HubScore" id="logo">
          <img src="assets/images/fr.png" [hidden]="language =='en'"alt="HubScore" id="logo">
                </a>
        <div class="dropdown-menu dropdownlanguage" ngbDropdownMenu aria-labelledby="navbarDropdown">
          <a class="dropdown-item" [ngClass]="{'active': language === 'en'}" href="#" (click)="changelanguage('en', $event)"><img src="assets/images/en.png" alt="HubScore" id="EN"> English</a>
          <a class="dropdown-item" [ngClass]="{'active': language === 'fr'}" href="#"  (click)="changelanguage('fr', $event)"><img src="assets/images/fr.png" alt="HubScore" id="FR"> Francais</a>
        </div>
      </li>
    </ul>

  </div>
</nav>