<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
<div class="page page-homepage" ng-intro="homepageIntroOptions" ng-intro-method="homepageIntroRun" style="margin-top: 20px;">
   <div class="row">
      <div class="col-lg-4 mb-20">
         <div class="row">
            <div class="col-lg-12 col-md-4" *ngFor="let item of annonces| keyvalue">
               <div *ngIf="item.key == 0" id="myaccountInfosBtn" [ngClass]="{active:item.value.id == userId}"  class="btn btn-block my-account__button my-account__button--drank" uib-btn-radio="'infos'">
                  {{ item.value.name }}
               </div>
               <div [ngClass]="{active:item.value.id == userId}" [ngClass]="{active:item.value.id == userId}" *ngIf="item.key == 1" id="myaccountInfosBtn"   class="btn btn-block my-account__button my-account__button--green" uib-btn-radio="'infos'">
                  {{ item.value.name }}
               </div>
               <div *ngIf="item.key == 2" id="myaccountInfosBtn" [ngClass]="{active:item.value.id == userId}"   class="btn btn-block my-account__button my-account__button--orange" uib-btn-radio="'infos'">
                  {{ item.value.name }}
               </div>
               <div *ngIf="item.key >= 3" id="myaccountInfosBtn" [ngClass]="{active:item.value.id == userId}"   class="btn btn-block my-account__button my-account__button--drank" uib-btn-radio="'infos'">
                  {{ item.value.name }}
               </div>             
            </div>
         </div>
      </div>
      <div class="col-md-8">
         <div class="container bg-white pt-4">
            <form name="issueForm" [formGroup]="registerForm" (ngSubmit)="saveUser()" novalidate role="form" class="form-list">
            <section class="tile">
               <div class="tile-header">
                     <button class="btn btn-drank btn-lg pull-right mr-4" style="width: 160px;margin-top: 0px;" (click)="onSelect(users['id'])">
                           {{ 'ACCOUNTS.BTNCNT' | translate }}
                        </button>
                  <strong style="color: #5e0468;font-size: 30px">{{ 'ACCOUNTS.EDITTITLE' | translate }}</strong>
                  <br>
                  <p class="custom-font">
                     <strong>{{ 'ACCOUNTS.H1STR1' | translate }}</strong>{{ 'ACCOUNTS.H1NOR1' | translate }}
                  </p>

               </div>
               <div class="form-group">
                     <div class="checkbox mb-15">
                           <label class="checkbox checkbox-custom">
                             <input type="checkbox" name="enabled" id="agree" formControlName="enabled" ><i></i>{{ 'ACCOUNTS.ENABLE' | translate }}
                           </label>
                         </div>
                  <div class="form-group formgroup_edit">
                     <label for="title" class="text-lg">{{ 'ACCOUNTS.USERNAME' | translate }} :</label>
                     <input type="text" formControlName="username" name="username" class="form-control" id="username" >
                  </div>
                  <div class="row">
                     <div class="form-group col-md-6">
                        <label for="password">{{ 'ACCOUNTS.PASSWORD' | translate }}:</label>
                        <input type="password"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                           <div *ngIf="f.password.errors.required">Password is required</div>
                           <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                     </div>
                     <div class="form-group col-md-6">
                        <label for="password">{{ 'ACCOUNTS.RPNPASSWORD' | translate }}:</label>
                        <input type="password" formControlName="plainPassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.plainPassword.errors }" />
                        <div *ngIf="submitted && f.plainPassword.errors" class="invalid-feedback">
                           <div *ngIf="f.plainPassword.errors.required">Password is required</div>
                           <div *ngIf="f.plainPassword.errors.minlength">Password must be at least 6 characters</div>
                        </div>
                     </div>
                  </div>
                  <div class="form-group formgroup_edit" ng-class="{ 'has-error' : userForm.email.$invalid && !userForm.email.$pristine, 'has-success' : userForm.email.$valid && !userForm.email.$pristine}">
                     <label for="email">{{ 'ACCOUNTS.EMAIL' | translate }}: </label>
                     <input type="email" formControlName="email"  name="email" class="form-control" id="email">
                     <p class="help-block">
                     </p>
                  </div>
                  <div class="row">
                     <div class="form-group col-md-6" ng-class="{ 'has-error' : userForm.firstName.$invalid && !userForm.firstName.$pristine, 'has-success' : userForm.firstName.$valid && !userForm.firstName.$pristine}">
                        <label for="firstName">{{ 'ACCOUNTS.FIRSTNAME' | translate }}: </label>
                        <input type="text" formControlName="firstName"  name="firstName" class="form-control" id="firstName">
                        <p class="help-block">
                        </p>
                     </div>
                     <div class="form-group col-md-6" ng-class="{ 'has-error' : userForm.lastName.$invalid && !userForm.lastName.$pristine, 'has-success' : userForm.lastName.$valid && !userForm.lastName.$pristine}">
                        <label for="lastName">{{ 'ACCOUNTS.LASTNAME' | translate }}: </label>
                        <input type="text" formControlName="lastName"  name="lastName" class="form-control" id="lastName">
                        <p class="help-block">
                        </p>
                     </div>
                  </div>
                  <div class="form-group formgroup_edit" ng-class="{ 'has-error' : userForm.phone.$invalid && !userForm.phone.$pristine, 'has-success' : userForm.phone.$valid && !userForm.phone.$pristine}">
                     <label for="phone">{{ 'ACCOUNTS.TEL' | translate }}: </label>
                     <input type="text" formControlName="phone"  name="phone" class="form-control" id="phone">
                     <p class="help-block">
                     </p>
                  </div>
               </div>
               <div class="tile-header dvd dvd-btm">
                  <p class="custom-font"><strong>{{ users['username'] }} </strong>roles edition</p>
               </div>
               <div class="tile-body">
                  <p class="text-muted">{{ 'Roles.LABEL' | translate }}</p>
                  <table class="table table-hover  table-bordered">
                     <thead class="thead-light">
                        <tr>
                           <th scope="col">{{ 'Roles.NAME' | translate }}</th>
                           <th scope="col">{{ 'Roles.ACTIVE' | translate }}</th>
                           <th scope="col">{{ 'Roles.Explication' | translate }}</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr class="form-group" *ngFor="let item of roleuser | keyvalue ;index as i">

                              <td *ngIf="item.value === '1' && item.key != 'ROLE_USER'"><label class="control-label">{{ 'Role.' + item.key+'.label' | translate }}</label></td>
                              <td *ngIf="item.value === '1' && item.key != 'ROLE_USER'">
                                 <div class="">
                                    <div class="custom-control custom-switch" >
                                       <input type="checkbox"  class="custom-control-input" id="{{item.key}}" [checked]="checkbox(item.key)" name="userRole[]"
                                       (change)="checkChange(i, item)" >
                                       <label class="custom-control-label" for="{{item.key}}">

                                       </label>
                                    </div>
                                 </div>
                              </td>
                              <td *ngIf="item.value === '1' && item.key != 'ROLE_USER'">{{ 'Role.' + item.key+'.description' | translate }}</td>


                        </tr>
                     </tbody>
                  </table>
               </div>
            </section>
            <div class="float-right">

               <button class="btn btn-drank ml-2">{{ 'ACCOUNTS.BTNEDIT' | translate }}</button>

            </div>
            </form>
            <div class="float-right">
                  <button (click)="goBack()" class="btn btn-default ml-2">{{ 'ACCOUNTS.Cancel' | translate }}</button>
            </div>
         </div>
      </div>
   </div>
</div>
