import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Validators} from '@angular/forms';
import {AuthService} from './services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  locallang=localStorage.getItem('language');
  constructor(translate: TranslateService, private auth: AuthService) {
    if(!this.locallang ){
      localStorage.setItem('language', 'fr');
      translate.setDefaultLang('fr');
      translate.use('fr');

    }
    else{

    translate.setDefaultLang(this.locallang);
    translate.use(this.locallang);
  }

  }
  ngOnInit() {}


}
