<div id="sidebar-wrap">
  <ul id="navigation" nav-collapse ripple>
    <li id="navDmpDropdownContainer"   ui-sref-active="active"  routerLinkActive="active">
      <a id="navDmpDropdown" routerLink="/home">
        <i class="fa fa-home"></i>
        <span>{{ 'Menu.WELCOME' | translate }}</span>
      </a>
      <ul>
      </ul>
    </li>
    <li id="navCmpDropdownContainer " ui-sref-active="active" routerLinkActive="active" ng-class="">
      <a id="navCmpDropdown" routerLink="/reports/cmp" >
        <i class="fa fa-bar-chart"></i>
        <span>{{ 'Menu.REPORTS' | translate }}</span>
      </a>
    </li>
    <li ui-sref-active="active" ng-class=""  routerLinkActive="active">
      <a id="navReportsDropdown"  routerLink="/accounts">
        <i class="fa fa-user"></i>
        <span style="padding-left: 4px;"> {{ 'Menu.ACCOUNT' | translate }}</span>
      </a>
    </li>
  </ul>
</div>
