import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';

@Component({
  selector: 'app-page-controls',
  templateUrl: './page-controls.component.html',
  styleUrls: ['./page-controls.component.scss']
})
export class PageControlsComponent implements OnInit {
  activedTab="";
  constructor(
    private location: Location,
    private router: Router
  ) { 
    
    this.router.events
    .subscribe((event:NavigationStart) => {
      // You only receive NavigationStart events
      this.activedTab=event.url;

      
    });

  }

  ngOnInit(): void {

    
  }
  goBack() {
    this.location.back();
  }
  refreshPage() {
    window.location.reload();
  }

}
